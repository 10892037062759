// import React, { useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import Master from './Master';
// import './Courseconfiguration.css';

// const Courseconfigurationpgmdms = () => {
//   const {
//     roleid,baseurl,isloading,setisloading
//  } = useContext(Master);
//   const [tabledata, settabledata] = useState([]);
//   const [addedRows, setaddedRows] = useState([]);
//   const [modifiedRows, setmodifiedRows] = useState([]);

//   // Handle changes in table cells (both for added and modified rows)
//   const handleinputchange = (index, field, value) => {
//     const updatedData = [...tabledata];
//     updatedData[index][field] = field === 'active' ? value.target.checked : value.target.value;
//     settabledata(updatedData);

//     const row = updatedData[index];
//     if (!row.id) {
//       // If id is not available (newly added row)
//       if (!addedRows.some(item => item === row)) {
//         setaddedRows([...addedRows, row]);
//       }
//     } else {
//       // If id exists (modified row)
//       const updatedModifiedRows = modifiedRows.map(item =>
//         item.id === row.id ? row : item
//       );
//       if (!modifiedRows.some(item => item.id === row.id)) {
//         setmodifiedRows([...modifiedRows, row]);
//       } else {
//         setmodifiedRows(updatedModifiedRows);
//       }
//     }
//   };

//   // Add a new row with empty fields
//   const handleaddrow = () => {
//     const newRow = {program:'PG', category: 'MDS', course: '',coursecode:'', intake: '', active: false };
//     settabledata([...tabledata, newRow]);
//   };

//   // Send both added and modified rows to the backend
//   const handlepostdata = async () => {
//     try {
//       const dataToSend = {
//         added: addedRows,
//         modified: modifiedRows
//       };
////setisloading(true);

//       axios({
//         url: baseurl+"/courses",
//         method: "POST",
//         headers: {
//              authorization: `Bearer ${localStorage.getItem("token")}`  ,
//         },
//         data: {courses:dataToSend},
        
//     }).then((response) => {

//       if(response.data.success){
//         var result = response.data.data.filter(obj => {
//           return obj.category === "MDS"
//         })
        
//         settabledata(result)


//         setaddedRows([]);
//         setmodifiedRows([]);
//       }else{

//       }
//   })
//   .catch((error) => {
//       // 
      
   
//   });




//       // await axios.post('https://example.com/api/save-data', dataToSend);
//       // alert('Data saved successfully!');
//       // // Reset arrays after posting
//       // setaddedRows([]);
//       // setmodifiedRows([]);



//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//     finally{
//       setisloading(false);
//     }
//   };



//   useEffect (() => {
//    //setisloading(true);

//     axios({
//       url: baseurl+"/courses/pg/mds",
//       method: "GET",
//       headers: {
//            authorization: `Bearer ${localStorage.getItem("token")}`  ,
//       },
//       data: {},
      
//   }).then((response) => {
//     if(response.data.success){


//       settabledata(response.data.data)

//       setaddedRows([]);
//       setmodifiedRows([]);
//     }else{

//     }
// })
// .catch((error) => {
    
 
// })
// .finally(() => {
//   setisloading(false);
// });


  
//   }, []);




//   return (
//     <div className="datatable-container">
//        <h3>PG MDS Courses</h3>
//       <table className="datatable">
//         <thead>
//           <tr>
//             {/* <th>category</th> */}
//             <th>course</th>
//             {/* <th>intake</th> */}
//             <th>coursecode</th>
//             <th>active</th>
//           </tr>
//         </thead>
//         <tbody>
//           {tabledata.map((row, index) => (
//             <tr key={index}>
//               {/* <td>
//                 <input
//                   type="text"
//                   value={row.category}
//                   onChange={(e) => handleinputchange(index, 'category', e)}
//                 />
//               </td> */}
//               <td>
//                 <input
//                   type="text"
//                   value={row.course}
//                   onChange={(e) => handleinputchange(index, 'course', e)}
//                 />
//               </td>
//               {/* <td>
//                 <input
//                   type="number"
//                   value={row.intake}
//                   onChange={(e) => handleinputchange(index, 'intake', e)}
//                 />
//               </td> */}
//               <td>
//                 <input
//                   type="text"
//                   value={row.coursecode}
//                   onChange={(e) => handleinputchange(index, 'price', e)}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="checkbox"
//                   checked={row.active}
//                   onChange={(e) => handleinputchange(index, 'active', e)}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div><button className='bluebutton' onClick={handleaddrow}>Add New Course</button>
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <button className='greenbutton' onClick={handlepostdata}>Save Changes</button></div>
//           <br/><br/>
//     <div> </div> 
//     {isloading && (
//        <div className="loading-overlay">
//        <div className="spinner"></div>
//      </div>
//     )}
//     </div>
//   );
// };

// export default Courseconfigurationpgmdms;



////////////////////////////////////////////

import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Master from './Master';
import { TextField, Checkbox, Button, Snackbar, CircularProgress, Box, Typography, Alert } from '@mui/material';

const Courseconfigurationpgmdms = () => {
  const { roleid, baseurl, isloading, setisloading } = useContext(Master);
  const [tabledata, settabledata] = useState([]);
  const [addedRows, setaddedRows] = useState([]);
  const [modifiedRows, setmodifiedRows] = useState([]);
  const [message, setMessage] = useState(""); // For Snackbar message
  const [messageType, setMessageType] = useState("success"); // For Snackbar message type

  // Handle changes in table cells (both for added and modified rows)
  const handleInputChange = (index, field, value) => {
    const updatedData = [...tabledata];
    updatedData[index][field] = field === 'active' ? value.target.checked : value.target.value;
    settabledata(updatedData);

    const row = updatedData[index];
    if (!row.id) {
      // If id is not available (newly added row)
      if (!addedRows.some(item => item === row)) {
        setaddedRows([...addedRows, row]);
      }
    } else {
      // If id exists (modified row)
      const updatedModifiedRows = modifiedRows.map(item =>
        item.id === row.id ? row : item
      );
      if (!modifiedRows.some(item => item.id === row.id)) {
        setmodifiedRows([...modifiedRows, row]);
      } else {
        setmodifiedRows(updatedModifiedRows);
      }
    }
  };

  // Add a new row with empty fields
  const handleAddRow = () => {
    const newRow = { program: 'PG', category: 'MDS', course: '', coursecode: '', intake: '', active: false };
    settabledata([...tabledata, newRow]);
  };

  // Send both added and modified rows to the backend
  const handlePostData = async () => {
    try {
      const dataToSend = {
        added: addedRows,
        modified: modifiedRows
      };
     //setisloading(true);

      const response = await axios({
        url: baseurl + "/courses",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { courses: dataToSend },
      });

      if (response.data.success) {
        const result = response.data.data.filter(obj => obj.category === "MDS");
        settabledata(result);
        setaddedRows([]);
        setmodifiedRows([]);
        setMessage("Data saved successfully!");
        setMessageType("success");
      } else {
        setMessage("Failed to save data.");
        setMessageType("error");
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setMessage("Error saving data.");
      setMessageType("error");
    } finally {
      setisloading(false); // Hide loading spinner
    }
  };

  useEffect(() => {
   //setisloading(true);

    axios({
      url: baseurl + "/courses/pg/mds",
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          settabledata(response.data.data);
          setaddedRows([]);
          setmodifiedRows([]);
        } else {
          setMessage("Failed to fetch data.");
          setMessageType("error");
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setMessage("Error fetching data.");
        setMessageType("error");
      })
      .finally(() => {
        setisloading(false); // Hide loading spinner
      });
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>PG MDS Courses</Typography>

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>Course</th>
            <th>Course Code</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {tabledata.map((row, index) => (
            <tr key={index}>
              <td>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={row.course}
                  onChange={(e) => handleInputChange(index, 'course', e)}
                />
              </td>
              <td>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={row.coursecode}
                  onChange={(e) => handleInputChange(index, 'coursecode', e)}
                />
              </td>
              <td>
                <Checkbox
                  checked={row.active}
                  onChange={(e) => handleInputChange(index, 'active', e)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleAddRow}>Add New Course</Button>
        <Button variant="contained" color="success" onClick={handlePostData}>Save Changes</Button>
      </Box>

      {isloading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1300,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Snackbar for success/error message with Alert */}
      <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          onClose={() => setMessage("")}
          severity={messageType}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Courseconfigurationpgmdms;
