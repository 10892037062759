import "../Css/Header.css";
import Master from "../Master";
import constants from "../constants";
import Popover from "@mui/material/Popover";
import HeaderSvgIcon from "./Svg/HeaderSvgIcon";
import React, { useContext, useState } from "react";
import UniversityLogo from "../Assests/Image/UniversityLogo.png";
import LockResetIcon from '@mui/icons-material/LockReset';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, IconButton } from "@mui/material";
import { jwtDecode } from "jwt-decode"; 

export default function Header() {
  const MasterCxt = useContext(Master);

  const [anchorEl, setAnchorEl] = useState(null);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [showNewPassword, setShowNewPassword] = useState(false);
   const [isPasswordValid, setIsPasswordValid] = useState(null); // State to track password validity
   const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    MasterCxt.navigate("/settings/academicyear");
  };

  const handleLogOut = () => {
    localStorage.clear();
    MasterCxt.navigate("/login");
    window.location.reload();
  };
  const handleResetPasswordOpen = () => {
    setResetDialogOpen(true);
    handlePopoverClose();
  };

  const handleResetPasswordClose = () => {
    setResetDialogOpen(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  const handlePasswordSubmit = async() => {
    // Logic to handle password reset
    setResetDialogOpen(false);

   const regex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (newPassword !== confirmPassword && newPassword !== regex) {
      alert("Passwords do not match");

      return;
    }
    const decoded = jwtDecode(localStorage.getItem("token"));
    const userid = decoded.userid;

    try {
      const response = await axios({
        method: "PUT",
        url: MasterCxt.baseurl + "/password",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          userid: userid,
          newpassword: newPassword,
        },
      });

      if (response.data.success) {
        alert("Password reset successful");
        handleResetPasswordClose();
      } else {
        const error = await response.json();

        alert(error.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred. Please try again later.");
    }
  
  };  


  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
  
    // Regex for password validation
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setIsPasswordValid(regex.test(value)); // Update state based on regex validation
  };
  const open = Boolean(anchorEl);
  return (
    <header className="headerMainContainer">
      <div className="headerUniversityDiv">
        <img
          src={UniversityLogo}
          alt="UniversityLogo"
          className="headerUniversityLogo"
        />
        <div className="headerUniversityName">
          Kaloji Narayana Rao University of Health Sciences
        </div>
      </div>
      <div className="headerRightDiv">
        {MasterCxt.roleid === parseInt(process.env.REACT_APP_ADMIN) && (
          <HeaderSvgIcon
            onClick={handleSettings}
            icon={constants.HEADER.SETTINGS}
          />
        )}
        <HeaderSvgIcon icon={constants.HEADER.PROFILE} onClick={handleClick} />
        <Popover
          open={open}
          anchorEl={anchorEl}
          id="headerProfilePopover"
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="headerLogout">
            <HeaderSvgIcon />
            <span className="headerAvatarLogout" onClick={handleLogOut}>
              Log Out
            </span>
              </div>
              <div className="passwordReset">
              <LockResetIcon
             style={{ cursor: "pointer" , color: 'rgb(102, 112, 133)' }}
             
             />
             {/* Reset Password Dialog */}
             <span className="headerAvatarLogout" onClick={handleResetPasswordOpen}>
             Reset Password
             </span>
              </div>
              </Popover>
        <Dialog open={resetDialogOpen} onClose={handleResetPasswordClose}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="newPassword"
              label="New Password"
              value={newPassword}
              regex = {/^(.{0,7}|[0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/}
              onChange={handleNewPasswordChange}
              type={showNewPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: isPasswordValid === null
                    ? "rgb(107, 107, 107)" // Default blue
                    : isPasswordValid
                    ? "green"
                    : "red",
                  "&.Mui-focused": {
                    color: isPasswordValid === null
                      ? "rgb(54, 134, 214)" // Brighter blue when focused
                      : isPasswordValid
                      ? "green"
                      : "red",
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}  
                    </IconButton>
                  </InputAdornment>
                ),
              }}

              sx={{
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isPasswordValid == null
          ? "rgba(0, 0, 255, 0.5"
          : isPasswordValid
          ? "green"
          : "red",
      },
      "&:hover fieldset": {
        borderColor: isPasswordValid == null
          ? "rgba(0, 0, 255, 0.5"
          : isPasswordValid
          ? "green"
          : "red",
      },
      "&.Mui-focused fieldset": {
        borderColor: isPasswordValid == null
          ? "rgba(0, 0, 255, 0.5"
          : isPasswordValid
          ? "green"
          : "red",
      },
      
    },
  }}
            />
            <TextField
              margin="dense"
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
              value={confirmPassword}
              variant="outlined"
              onChange={(e) =>{setConfirmPassword(e.target.value);
                setIsConfirmPasswordValid(e.target.value === newPassword);}}

                InputLabelProps={{
                  sx: {
                    color: isPasswordValid === null
                      ? "rgb(107, 107, 107)" // Default blue
                      : isConfirmPasswordValid
                      ? "green"
                      : "red",
                    "&.Mui-focused": {
                      color: isConfirmPasswordValid === null
                        ? "rgb(54, 134, 214)" // Brighter blue when focused
                        : isConfirmPasswordValid
                        ? "green"
                        : "red",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: isConfirmPasswordValid == null
                        ? "rgba(0, 0, 255, 0.5"
                        : isConfirmPasswordValid
                        ? "green"
                        : "red",
                    },
                    "&:hover fieldset": {
                      borderColor: isConfirmPasswordValid == null
                        ? "rgba(0, 0, 255, 0.5"
                        : isConfirmPasswordValid
                        ? "green"
                        : "red",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: isConfirmPasswordValid == null
                        ? "rgba(0, 0, 255, 0.5"
                        : isConfirmPasswordValid
                        ? "green"
                        : "red",
                    },
                    
                  },
                }}

            />
            <div style={{ marginTop: "10px", fontSize: "14px", color: "gray" }}>
    <p>Password must meet the following criteria:</p>
    <ul>
      <li>At least 8 characters long</li>
      <li>Include at least one uppercase letter</li>
      <li>Include at least one lowercase letter</li>
      <li>Include at least one number</li>
      <li>Include at least one special character (e.g., @, #, $, %, etc.)</li>
    </ul>
  </div>
          </DialogContent>
          <DialogActions>
            <Button  onClick={handleResetPasswordClose}>Cancel</Button>
            <Button className = "resetPasswordSubmit"  onClick={handlePasswordSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
         
      </div>
    </header>
  );
}
