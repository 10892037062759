import axios from "axios";
import Master from "./Master";
import { jwtDecode } from "jwt-decode";
import { useNavigate, Outlet } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useContext, useState, useEffect } from "react";

import {
  Box,
  Dialog,
  Button,
  TextField,
  IconButton,
  DialogTitle,
  CssBaseline,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";

const Layout = () => {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { baseurl, roleid, setroleid, setuserid } = useContext(Master);

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");

      return;
    }

    const userid = localStorage.getItem("userid");

    try {
      const response = await axios({
        method: "PUT",
        url: baseurl + "/password",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          userid: userid,
          newpassword: newPassword,
        },
      });

      if (response.data.success) {
        alert("Password reset successful");
        handleCloseResetDialog();
      } else {
        const error = await response.json();

        alert(error.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    

    if (!roleid) {
      if (localStorage.getItem("token") === null) {
        if (
          window.location.pathname === "/login" ||
          window.location.pathname === "/register"
        ) {
          navigate(window.location.pathname);
        }
      } else {
        const decoded = jwtDecode(localStorage.getItem("token"));

        setuserid(decoded.userid);
        setroleid(decoded.roleid);
      }
    }
  }, [, roleid]);

  

  return (
    <div className="newLayoutMainContainer">
      <CssBaseline />
      <Outlet />
      <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            type="password"
            margin="normal"
            value={confirmPassword}
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetDialog}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Layout;
