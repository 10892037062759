import axios from "axios";
import Master from "../../Master";
import "../../Css/RequestDetailPage/AcademicYearDisplay.css";
import React, { useContext, useEffect, useState } from "react";
import { Snackbar, Alert, Box, CircularProgress } from "@mui/material";

export default function AcademicYearDisplay() {
  const MasterCxt = useContext(Master);

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");

  useEffect(() => {
    MasterCxt.setisloading(true);
    axios({
      url: MasterCxt.baseurl + "/academicyears/current",
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          const { start, end } = response.data.data;
          const formattedYear = `${start + ""}-${(end + "").substring(2, 4)}`;

          MasterCxt.setacademicyear(formattedYear);
          MasterCxt.setacademicyearid(response.data.data.id);
          MasterCxt.setiscurrent(response.data.data.iscurrent === 1);
          setMessage(response.data.message);
          setMessageType("success");
        }
      })
      .catch((error) => {
        console.error("Error fetching current academic year:", error);

        setMessage("Your session has expired. Redirecting to login...");
        setMessageType("error");

        setTimeout(() => {
          localStorage.removeItem("token");
          MasterCxt.navigate("/login");
        }, 2000);
      })
      .finally(() => {
        MasterCxt.setisloading(false);
      });
  }, []);

  return (
    <div className="academicYearDisplayMainContainer">
      <div className="academicYearDisplayHeader">Academic Year</div>
      <div className="academicYearDisplayBody">
        {MasterCxt.academicyear || "—"}
      </div>

      {/* <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          severity={messageType}
          sx={{ width: "100%" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      </Snackbar> */}

      {MasterCxt.isloading && (
        <Box
          sx={{
            top: "50%",
            left: "50%",
            zIndex: 1300,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
