import React, { createContext, useState } from "react";
import _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Master = createContext();

export const MasterContext = ({ children }) => {
  const navigate = useNavigate();

  const [registrationformdata, setregistrationformdata] = useState({
    managementtypeid: null,
  });
  const [loginformdata, setloginformdata] = useState({});
  const [isloggedin, setisloggedin] = useState(false);
  const [isregistered, setisregistered] = useState(false);
  const [isrequestpayment, setisrequestpayment] = useState(false);
  const [academicyear, setacademicyear] = useState("");
  const [program, setprogram] = useState("");
  const [selectedcourses, setselectedcourses] = useState([]);
  const [courseinput, setcourseinput] = useState("");
  const [activetab, setactivetab] = useState("configuration");
  const [theroute, settheroute] = useState("");
  const [forgotpassword, setforgotpassword] = useState(false);
  const [selectedoption, setselectedoption] = useState(null);
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [managementtypes, setmanagementtypes] = useState([]);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const [academicyearAdmin, setacademicyearadmin] = useState("");
  const [userid, setuserid] = useState(null);
  const [registrationformvalidationdata, setregistrationformvalidationdata] =
    useState({
      collegename: null,
      managementtypeid: null,
      registrationnumber: null,
      address: null,
      telephone: null,
      email: null,
      principalmobile: null,
      websiteurl: null,
      // collegecode: null
    });
  const [loginformvalidationdata, setloginformvalidationdata] = useState({
    email: null,
    password: null,
  });

  const [programlist, setprogramlist] = useState([]);
  const [loggedinuserdata, setloggedinuserdata] = useState({});
  const [newrequest, setnewrequest] = useState(false);

  ////////////////////
  const [roleid, setroleid] = useState(null);
  const [managementtype, setmanagementtype] = useState("");
  /////////////////////////////////////////////////////////////////////////////

  const [fromyear, setfromyear] = useState("");
  const [toyear, settoyear] = useState("");
  const [iscurrent, setiscurrent] = useState(false);
  const [academicyearid, setacademicyearid] = useState(false);
  const [selectedprogram, setselectedprogram] = useState("");
  const [collegrequestedocs, setcollegrequestedocs] = useState([]);
  const [affiliationrequestid, setaffiliationrequestid] = useState(null);
  const [newaffiliationrequest, setnewaffiliationrequest] = useState(null);
  const [accessorrequestdocs, setaccessorrequestdocs] = useState([]);
  const [affiliationtypeid, setaffiliationtypeid] = useState(null);
  const [finalcertificatedoc, setfinalcertificatedoc] = useState([]);

  const [pgsuboptions, setpgsuboptions] = useState("");



  const [selectedrequestobject, setselectedrequestobject] = useState({});
  const [
    affiliationrequestsubmissioncompletion,
    setaffiliationrequestsubmissioncompletion,
  ] = useState({});
  const [isloading, setisloading] = useState(false);
  const [showformsubmissionbutton, setshowformsubmissionbutton] =
    useState(null);
  const [successmessage, setsuccessmessage] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const [ugtabledata, setugtabledata] = useState([]);
  const [ugmodifiedrows, setugmodifiedrows] = useState([]);
  const [pgmdstabledata, setpgmdstabledata] = useState([]);
  const [pgmdsmodifiedrows, setpgmdsmodifiedrows] = useState([]);
  const [pgmdmstabledata, setpgmdmstabledata] = useState([]);
  const [pgmdmsmodifiedrows, setpgmdmsmodifiedrows] = useState([]);
  const [assessorpaymentcomplete, setassessorpaymentcomplete] = useState(false);
  const [affiliationpaymentcomplete, setaffiliationpaymentcomplete] =
    useState(false);
  const [updatedrequestcourses, setupdatedrequestcourses] = useState([]);

  const [ugsuboptions, setugsuboptions] = useState("");
  const [disablesaveandcontinue, setdisablesaveandcontinue] = useState(false);
  const [isnewrequest, setisnewrequest] = useState(false);
  const [showuploadfilessection, setshowuploadfilessection] = useState(false);
  const [provisionalsuboption, setprovisionalsuboption] = useState("");
const [selectedparent, setselectedparent] = useState(null);
  const [hasnewcoursefeature, sethasnewcoursefeature] = useState(false);
  const [needslop, setneedslop] = useState(false);

  const updateregistrationformdata = (field, value) => {
    setregistrationformdata((prevdata) => ({ ...prevdata, [field]: value }));
  };

  const updateregistrationformvalidationdata = (field, value) => {
    setregistrationformvalidationdata((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const doesformhaserror = (formvalidationData) => {
    var ithaserror = Object.values(formvalidationData).some(
      (value) => value === null || value === "" || value === true
    );
    return ithaserror;
  };
  const updateloginformdata = (field, value) => {
    setloginformdata((prevdata) => ({ ...prevdata, [field]: value }));
  };
  const updateloginformvalidationdata = (field, haserror) => {
    setloginformvalidationdata((prevstate) => ({
      ...prevstate,
      [field]: haserror,
    }));
  };

  const updateshowformsubmissionbutton = () => {
    if (Object.values(affiliationrequestsubmissioncompletion).length > 0) {
      if (
        Object.values(affiliationrequestsubmissioncompletion).includes(null) ||
        Object.values(affiliationrequestsubmissioncompletion).includes(false)
      ) {
        setshowformsubmissionbutton(false);
      } else {
        setshowformsubmissionbutton(true);
      }
    } else {
      setshowformsubmissionbutton(false);
    }

    const values = Object.values(affiliationrequestsubmissioncompletion);
    const allCompleted =
      values.length > 0 && values.every((value) => value === true);

    setshowformsubmissionbutton(allCompleted);
  };

  const updateselectedprogram = (name) => {
    // setselectedprogram((prev) => ({
    //   ...prev,
    //   [name]: checked,
    // }));
    setselectedprogram(name)
  };

  const updatepgsuboptions = (name) => {
    // setpgsuboptions((prev) => ({
    //   ...prev,
    //   [name]: checked,
    // }));
    setpgsuboptions(name);
  };
  const updateugsuboptions = (name) => {

    setugsuboptions(name);
  };

  function updaterequestcourses() {
    var mofifiedrequestcourses = [
      ...ugmodifiedrows,
      ...pgmdmsmodifiedrows,
      ...pgmdsmodifiedrows,
    ];

    var mofifiedrequestcourses = _.filter(mofifiedrequestcourses, function (n) {
      return !isNaN(n.noofstudents);
    });

    mofifiedrequestcourses = _.map(mofifiedrequestcourses, (o) =>
      _.pick(o, ["coursecode", "courseid", "noofstudents"])
    );

    // setupdatedrequestcourses(mofifiedrequestcourses);

    setupdatedrequestcourses((prev) => {
      return [...mofifiedrequestcourses];
    });
  }


  const handlerequestcourses = async () => {


    var checkingtable = []
    if (selectedprogram == "PG") {

      if (pgsuboptions == "MDS") {

        if (affiliationtypeid >= 4) {
          checkingtable = pgmdstabledata.filter((data) => data.editable == false);
        }
        if (affiliationtypeid <= 3) {
          checkingtable = pgmdstabledata
        }

      }
      if (pgsuboptions == "MD_MS") {
        if (affiliationtypeid >= 4) {
          checkingtable = pgmdmstabledata.filter((data) => data.editable == false);
        }

        if (affiliationtypeid <= 3) {
          checkingtable = pgmdmstabledata;
        }
      }
    }

    if (selectedprogram == "UG") {
      if (affiliationtypeid >= 4) {
        checkingtable = ugtabledata.filter((ug) => ug.course == ugsuboptions).filter((data) => data.editable == false);
      }

      if (affiliationtypeid <= 3) {
        checkingtable = ugtabledata.filter((ug) => ug.course == ugsuboptions);
      }

    }

    var endpoint;
    if (affiliationtypeid >= 4) {
      endpoint = "/courses/renewal";
    }

    if (affiliationtypeid <= 3) {
      endpoint = "/courses/new";
    }


    axios({
      url: baseurl + endpoint,
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        academicyearid: parseInt(academicyearid), // Replace with the actual academicyearid
        affiliationtypeid: parseInt(affiliationtypeid),
        courses: checkingtable,
        affiliationrequestid: parseInt(affiliationrequestid),
      },
    })
      .then((response) => {
        if (response.data.success) {
          setaffiliationrequestid(response.data.data.affiliationrequestid);



          setdisablesaveandcontinue(true);
          setshowuploadfilessection(true);



        }
      })
      .catch((error) => {
        console.error("There was an error registering the college!", error);
        setshowuploadfilessection(false)
      })
      .finally(() => {
        setisloading(false);
      });
  };

  function checkpaymentcompleted(data, paymenttypeid) {
    const paymentcompleted = data.some(
      (item) =>
        item.paymenttypeid === paymenttypeid &&
        item.paymentstatus === "Completed"
    );
  }



  function checksaveandcontinue(affiliationdata) {
    
    var checkingtable = []
    if (selectedprogram == "PG") {
      if (pgsuboptions == "MDS") {
        checkingtable = pgmdstabledata;
      }
      if (pgsuboptions == "MD_MS") {
        checkingtable = pgmdmstabledata;
      }
    }
    if (selectedprogram == "UG") {
      // if(ugsuboptions=="MBBS"){
      checkingtable = ugtabledata.filter((ug) => ug.course == ugsuboptions);
      // }
      // if(ugsuboptions=="BDS"){

      // }
    }


    if (affiliationdata != undefined) {
      checkingtable = affiliationdata
    }
    
    var editablecount = 0;

    console.log("checkingtable", checkingtable);  
    

    for (var i = 0; i < checkingtable.length; i++) {

      if(needslop==true && hasnewcoursefeature==false){

        if (
          checkingtable[i].fileuploadsuccess == null || 
          checkingtable[i].fileuploadsuccess == false || 
          checkingtable[i].seatsupdated==true
        ) {
          setdisablesaveandcontinue(true);
          return;
        }
 
    }

    if(needslop==true && hasnewcoursefeature==true){

      if(checkingtable[i].editable){
        editablecount=editablecount+1;

          if(checkingtable[i].fileuploadsuccess==false || checkingtable[i].seatsupdated==true || checkingtable[i].fileselected==false){
            setdisablesaveandcontinue(true);
            return;
          }
        }

    }



    if(needslop==false && hasnewcoursefeature==true){
      console.log("checkingtable[i].seatsupdated");
      

      if(checkingtable[i].editable){
        editablecount=editablecount+1;

          if( checkingtable[i].seatsupdated==false || checkingtable[i].seatsupdated==null ){
            setdisablesaveandcontinue(true);
            return;
          }
        }

    

    }
  }
    

    if((needslop==true ||needslop==false ) && hasnewcoursefeature==true && editablecount==0){
      
      setdisablesaveandcontinue(true);
      return;
    } 
    setdisablesaveandcontinue(false);


  }

  return (
    <Master.Provider
      value={{
        registrationformdata,
        navigate,
        updateregistrationformdata,
        loginformdata,
        updateloginformdata,
        activetab,
        setactivetab,
        academicyear,
        setacademicyear,
        program,
        setprogram,
        selectedcourses,
        setselectedcourses,
        courseinput,
        setcourseinput,
        selectedoption,
        setselectedoption,
        forgotpassword,
        setforgotpassword,
        baseurl,
        managementtypes,
        setmanagementtypes,
        updateregistrationformvalidationdata,
        registrationformvalidationdata,
        doesformhaserror,
        loginformvalidationdata,
        updateloginformvalidationdata,
        isrequestpayment,
        setisrequestpayment,
        setregistrationformvalidationdata,
        fromyear,
        setfromyear,
        toyear,
        settoyear,
        iscurrent,
        setiscurrent,
        userid,
        setuserid,
        academicyearid,
        setacademicyearid,
        selectedprogram,
        setselectedprogram,
        //  collegedocs,setcollegedocs,
        collegrequestedocs,
        setcollegrequestedocs,
        newaffiliationrequest,
        setnewaffiliationrequest,
        affiliationrequestid,
        setaffiliationrequestid,
        accessorrequestdocs,
        setaccessorrequestdocs,
        roleid,
        setroleid,
        newrequest,
        setnewrequest,
        affiliationtypeid,
        setaffiliationtypeid,
        pgsuboptions,
        setpgsuboptions,
        selectedrequestobject,
        setselectedrequestobject,
        affiliationrequestsubmissioncompletion,
        setaffiliationrequestsubmissioncompletion,
        setshowformsubmissionbutton,
        showformsubmissionbutton,
        updateshowformsubmissionbutton,
        setshowformsubmissionbutton,
        updateselectedprogram,
        updatepgsuboptions,
        managementtype,
        setmanagementtype,
        finalcertificatedoc,
        setfinalcertificatedoc,
        errormessage,
        seterrormessage,
        successmessage,
        setsuccessmessage,
        isloading,
        setisloading,
        ugtabledata,
        setugtabledata,
        ugmodifiedrows,
        setugmodifiedrows,
        pgmdstabledata,
        setpgmdstabledata,
        pgmdsmodifiedrows,
        setpgmdsmodifiedrows,
        pgmdmstabledata,
        setpgmdmstabledata,
        pgmdmsmodifiedrows,
        setpgmdmsmodifiedrows,
        handlerequestcourses,
        setpgsuboptions,
        setassessorpaymentcomplete,
        assessorpaymentcomplete,
        setaffiliationpaymentcomplete,
        affiliationpaymentcomplete,
        updaterequestcourses,
        updatedrequestcourses,
        ugsuboptions,
        setugsuboptions,
        updateugsuboptions,
        checksaveandcontinue,
        disablesaveandcontinue,
        setdisablesaveandcontinue,
        isnewrequest, setisnewrequest,
        showuploadfilessection, setshowuploadfilessection,
        provisionalsuboption, setprovisionalsuboption,
        hasnewcoursefeature, sethasnewcoursefeature,
        needslop, setneedslop,
        provisionalsuboption,setprovisionalsuboption,
        selectedparent, setselectedparent
      }}
    >
      {children}
    </Master.Provider>
  );
};

export default Master;
