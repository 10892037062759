import "./App.css";
import Master from "./Master";
import Workflow from "./Workflow";
import NewLayout from "./NewLayout";
import { jwtDecode } from "jwt-decode";
import Requestlist from "./Requestlist";
import Header from "./Components/Header";
import GST from "./Components/Menubar/GST";
import { useContext, useEffect } from "react";
import SelectAssessor from "./SelectAssessor";
import LoginPage from "./Components/LoginPage";
import MenuBar from "./Components/Menubar/MenuBar";
import AcademicYearInput from "./AcademicYearInput";
import ConsentFeeStructure from "./ConsentFeeStructure";
import BankDetail from "./Components/Menubar/BankDetail";
import AssessorFeeStructure from "./AssessorFeeStructure";
import CollegeCode from "./Components/Menubar/CollegeCode";
import Courseconfigurationug from "./Courseconfigurationug";
import RegistrationPage from "./Components/RegistrationPage";
import AffiliationFeeStructure from "./AffiliationFeeStructure";
import ContinuationFeeStructure from "./ContinuationFeeStructure";
import Courseconfigurationpgmds from "./Courseconfigurationpgmds";
import Courseconfigurationpgmdms from "./Courseconfigurationpgmdms";
import UniversityBankCode from "./Components/Menubar/UniversityBankCode";
import Profile from "./Components/RequestDetailPage/Profile";

import {
  Route,
  Routes,
  useNavigate,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

export default function App() {
  const { roleid, userid, setroleid, setuserid } = useContext(Master);

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const MasterCxt = useContext(Master);

  useEffect(() => {
    if (!roleid || !userid) {
      if (localStorage.getItem("token") === null) {
        if (
          window.location.pathname === "/login" ||
          window.location.pathname === "/register"
        ) {
          navigate(window.location.pathname);
        }
      } else {
        const decoded = jwtDecode(localStorage.getItem("token"));
        setuserid(decoded.userid);
        setroleid(decoded.roleid);
      }
    }
  }, []);

  useEffect(()=>{
    MasterCxt.setisnewrequest(Object.keys(MasterCxt.selectedrequestobject).length == 0);

    if (Object.keys(MasterCxt.selectedrequestobject).length > 0) {
    if(MasterCxt.selectedrequestobject.status==null){
      
      
      MasterCxt.setdisablesaveandcontinue(false);
    }

    if(MasterCxt.selectedrequestobject.status!=null){
      
      MasterCxt.setdisablesaveandcontinue(true);
    }

  }

  },[MasterCxt.selectedrequestobject])
  

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Inter"
      />
      {pathname !== "/login" && <Header />}
      <Routes>
        <Route path="/settings" element={<MenuBar />}>
          <Route path="gst" element={<GST />} />
          <Route path="bankdetails" element={<BankDetail />} />
          <Route path="collegecode" element={<CollegeCode />} />
          <Route path="consentfee" element={<ConsentFeeStructure />} />
          <Route path="assessorfee" element={<AssessorFeeStructure />} />
          <Route index path="academicyear" element={<AcademicYearInput />} />
          <Route path="universitybankcode" element={<UniversityBankCode />} />
          <Route
            path="continuationfee"
            element={<ContinuationFeeStructure />}
          />
          <Route
            path="coursecode"
            element={
              <>
                <Courseconfigurationug />
                <Courseconfigurationpgmds />
                <Courseconfigurationpgmdms />
              </>
            }
          />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<NewLayout />}>
          <Route path="/" element={<Header />} />
          <Route index element={
            <>
            {roleid==process.env.REACT_APP_COLLEGE &&<>
              <br/>
              <br/>
            <Profile />
            </>}
            
            <Requestlist />
            </>
            } />
          <Route path="home" element={
            
            <>
            {roleid==process.env.REACT_APP_COLLEGE &&<>
            <br/>
            <br/>
            <Profile />
            </>}
            
            <Requestlist />
            </>
            
            } />
          <Route path="requests" element={
            
            <>
            {roleid==process.env.REACT_APP_COLLEGE &&<>
              <br/>
              <br/>
            <Profile />
            </>}
            
            <Requestlist />
            </>
            
            } />
          <Route path="requests/:id" element={<Workflow />} />
          <Route path="requests/new" element={<Workflow />} />
          <Route path="assessor" element={<SelectAssessor />} />
          <Route path="register" element={<RegistrationPage />} />
        </Route>
        <Route path="affiliationfee" element={<AffiliationFeeStructure />} />
      </Routes>
    </div>
  );
}
