import axios from "axios";
import "../Css/LoginPage.css";
import Master from "../Master";
import Text from "../elements/text/Text";
import Button from "../elements/button/Button";
import React, { useContext, useEffect, useState } from "react";
import UniversityLogo from "../Assests/Image/UniversityLogo.png";
import { Snackbar, Alert, Box, CircularProgress } from "@mui/material";

export default function LoginPage() {
  const MasterCxt = useContext(Master);

  const [message, setMessage] = useState("");
  const [isloggedin, setisloggedin] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [formhaserror, setformhaserror] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const handlechange = (field, value) => {
    MasterCxt.updateloginformdata(field, value);
  };

  const updateformvalidationdata = (name, value) => {
    MasterCxt.updateloginformvalidationdata(name, value);
  };

  // Iu40XbGaPF

  useEffect(() => {
    let haserrors = MasterCxt.doesformhaserror(MasterCxt.loginformvalidationdata);

    setformhaserror(haserrors);
  }, [MasterCxt.loginformvalidationdata]);

  const handlelogin = (e) => {
    // console.log("OOOOOOOOOOOOOOOOOOOOOOOOOO");
    
    e.preventDefault();
    seterrorMessage("");
    MasterCxt.setisloading(true);

    const token = localStorage.getItem("accessToken");
  //  console.log("OOOOOOOOOOOOOOOOOOOOOOOOOO");
    axios({
      method: "POST",
      data: MasterCxt.loginformdata,
      url: MasterCxt.baseurl + "/login",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY");
        
        if (response.data.success) {
          localStorage.setItem("token", response.data.data["token"]);

          setisloggedin(true);
          setMessageType("succcess");
          setMessage(response.data.message);
          MasterCxt.navigate("/");
        } else {
          setMessageType("error");
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log("EEEEEEEEEEEEEEEEEEE   ");
        
        console.error(
          "There was an error registering the college!",
          error.response
        );

        setMessage(error.response.data.message);
        setMessageType("error");
      })
      .finally(() => {
        console.log("QQQQQQQQQQQQQQQQQQQQQQQQQq");
        
        MasterCxt.setisloading(false);
        setOpenSnackbar(true);
      });
  };

  const handleforgotpassword = (e) => {
    MasterCxt.setforgotpassword(true);
    e.preventDefault();
    MasterCxt.setisloading(true);

    axios({
      method: "POST",
      data: MasterCxt.loginformdata,
      url: MasterCxt.baseurl + "/forgotpassword",
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response, "response..............");

          setMessageType("success");
          setMessage(response.data.message);
        } else {
          setMessageType("error");
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error registering the college!", error);

        setMessageType("error");
        setMessage(error.response?.data?.message);
      })
      .finally(() => {
        MasterCxt.setisloading(false);
        setOpenSnackbar(true);
      });
  };

  const handlenewregistration = () => {
    // Navigate to forgot password page or show modal

    MasterCxt.navigate("/register");
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="loginPageMainContainer">
      <div className="loginPageCollegeInfo">
        <img
          src={UniversityLogo}
          alt="UniversityLogo"
          className="loginPageCollegeLogo"
        />
        <div className="loginPageCollegeName">
          Kaloji Narayanarao University of Health Sciences
        </div>
        <div className="loginPageCollegeSubHeader">
          Empowering education, enabling progress.
        </div>
      </div>
      <div className="loginPageForm">
        <div className="loginPageFormHeader">
          {!MasterCxt.forgotpassword ? "Login" : "Reset Password"}
        </div>
        <Text
          required
          name="email"
          label="Enter Login ID"
          regx={/^\S+@\S+\.\S+$/} // Email regex for validation
          className="loginPageFormLabel"
          value={MasterCxt.loginformdata.email}
          sethaserror={updateformvalidationdata}
          errortext="Please enter a valid email address"
          update={(value) => handlechange("email", value)}
        />
        {MasterCxt.forgotpassword ? (
          <>
            <div
              className="loginPageForgotPwd"
              onClick={() => {
                MasterCxt.setforgotpassword(false);
                seterrorMessage("");
              }}
            >
              Login?
            </div>
            <Button
              label="Reset"
              className="loginBtn"
              onclick={handleforgotpassword}
            />
          </>
        ) : (
          <>
            <Text
              required
              name="password"
              type="password"
              regx={/^[\s\S]{8,}$/}
              label="Enter Password"
              className="loginPageFormLabel"
              sethaserror={updateformvalidationdata}
              value={MasterCxt.loginformdata.password}
              update={(value) => handlechange("password", value)}
              errortext="Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character."
            />
            <div
              className="loginPageForgotPwd"
              onClick={() => {
                MasterCxt.setforgotpassword(true);
                seterrorMessage("");
              }}
            >
              Forgot Password?
            </div>
            <Button
              label="Login"
              className="loginBtn"
              onclick={handlelogin}
              disabled={formhaserror}
            />
            <div className="loginPageFooter">
              <span className="loginPageFooterLabel">
                Don't have an account?
              </span>

              <span
                className="loginPageFooterRegister"
                onClick={() => {
                  handlenewregistration();
                  seterrorMessage("");
                }}
              >
                Register Now
              </span>
            </div>
          </>
        )}
      </div>

      {MasterCxt.isloading && (
        <Box
          sx={{
            top: "50%",
            left: "50%",
            zIndex: 1300,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={messageType}
          sx={{ width: "100%" }}
          onClose={handleSnackbarClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
