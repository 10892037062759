import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Alert } from '@mui/lab';
import Master from './Master';

const ConsentFeeStructure = () =>{

    const {
        baseurl
     } = useContext(Master);
      const [feeStructures, setFeeStructures] = useState({ MBBS: [], BDS: [], MDS:[], MD_MS:[] });
      const [loading, setLoading] = useState(false);
      const [newFeeStructure, setNewFeeStructure] = useState({ MBBS: [], BDS: [], MDS:[], MD_MS:[] }); // State to hold the new entry
      // const [token, setToken] = useState('YOUR_BEARER_TOKEN'); // Replace with actual logic to fetch the token
    
      const token = localStorage.getItem('token');  // Example: Retrieving token from localStorage
    
const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success'); // success or error

  // Fetch fee structures on mount
  useEffect(() => {
    fetchFeeStructures('MBBS');
    fetchFeeStructures('BDS');
    fetchFeeStructures('MDS');
    fetchFeeStructures('MD_MS');
      
  }, []);

  // Fetch fee structure data for a category
  const fetchFeeStructures = async (category) => {
    setLoading(true);
    try {
      const response = await axios.get(baseurl+`/fee/consent/${category}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setFeeStructures(prevState => ({
        ...prevState,
        [category.replace("/","_")]: Array.isArray(response.data.data) ? response.data.data : [],
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage('Failed to fetch fee structures');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleChange = (category, field, value, index = null) => {
    if (index !== null) {
      const updatedFeeStructures = feeStructures[category].map((fs, idx) =>
        idx === index ? { ...fs, [field]: value } : fs
      );
      setFeeStructures((prevState) => ({
        ...prevState,
        [category]: updatedFeeStructures,
      }));
    } else {
      // For new entry (empty row)
      setNewFeeStructure((prevState) => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          [field]: value,
        },
      }));
    }
  };

// Handle CRUD operations
const handleCreate = async (category, min_students, max_students, fee) => {
    setLoading(true);
    try {
      const response = await axios.post(
        baseurl+'/fee/consent',
        {
          category,
          min_students,
          max_students,
          fee,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(category); // Refresh list
      setNewFeeStructure((prevState) => ({ ...prevState, [category]: null })); // Reset new fee structure
    } catch (error) {
      setMessage('Failed to create fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (category, id, min_students, max_students, fee) => {
    setLoading(true);
    try {
      const response = await axios.put(
        baseurl+`/fee/consent/${id}`,
        { min_students, max_students, fee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(category); // Refresh list
    } catch (error) {
      setMessage('Failed to update fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (category, id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        baseurl+`/fee/consent/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(category);
    } catch (error) {
      setMessage('Failed to delete fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle adding new fee structure (creating)
  const handleAddNewFeeStructure = (category) => {
    // Create a new empty fee structure for the category
    setNewFeeStructure((prevState) => ({
      ...prevState,
      [category]: { min_students: '', max_students: '', fee: '' }, // Empty fields to enter data
    }));
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}

      <Grid container spacing={3}>
        {/* UG category */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>UG Fee Structure</Typography>
          <Typography variant="h6" gutterBottom>MBBS</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.MBBS.length > 0 ? (
                feeStructures.MBBS.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('MBBS', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('MBBS', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('MBBS', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('MBBS', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('MBBS', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for UG category.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for UG */}
              {newFeeStructure.MBBS && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MBBS.min_students}
                      onChange={(e) => handleChange('MBBS', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MBBS.max_students}
                      onChange={(e) => handleChange('MBBS', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MBBS.fee}
                      onChange={(e) => handleChange('MBBS', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('UG', newFeeStructure.UG.min_students, newFeeStructure.UG.max_students, newFeeStructure.UG.fee)}>
                      Create New UG Fee Structure
                    </Button> */}
                    <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate('MBBS', newFeeStructure.MBBS.min_students, newFeeStructure.MBBS.max_students, newFeeStructure.MBBS.fee)}
            sx = {{whiteSpace: 'nowrap'}}
          >
           Create New 
          </Button>
        </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddNewFeeStructure('MBBS')}
          >
           Add New 
          </Button>
        </Box>

        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <Typography variant="h6" gutterBottom>BDS</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.BDS.length > 0 ? (
                feeStructures.BDS.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('BDS', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('BDS', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('BDS', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('BDS', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('BDS', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for UG category.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for UG */}
              {newFeeStructure.BDS && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.BDS.min_students}
                      onChange={(e) => handleChange('BDS', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.BDS.max_students}
                      onChange={(e) => handleChange('BDS', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.BDS.fee}
                      onChange={(e) => handleChange('BDS', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('UG', newFeeStructure.UG.min_students, newFeeStructure.UG.max_students, newFeeStructure.UG.fee)}>
                      Create New UG Fee Structure
                    </Button> */}
                    <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate('BDS', newFeeStructure.BDS.min_students, newFeeStructure.BDS.max_students, newFeeStructure.BDS.fee)}
            sx = {{whiteSpace: 'nowrap'}}
          >
           Create New 
          </Button>
        </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* <Button onClick={() => handleAddNewFeeStructure('UG')}>Add New UG Fee Structure</Button> */}
          <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddNewFeeStructure('BDS')}
          >
           Add New 
          </Button>
        </Box>
        </Grid>

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* PG category */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>PG Fee Structure</Typography>
          <Typography variant="h6" gutterBottom>MD/MS</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.MD_MS.length > 0 ? (
                feeStructures.MD_MS.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('MD_MS', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('MD_MS', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('MD_MS', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('MD_MS', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('MD_MS', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for PG category.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for PG */}
              {newFeeStructure.MD_MS && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MD_MS.min_students}
                      onChange={(e) => handleChange('MD_MS', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MD_MS.max_students}
                      onChange={(e) => handleChange('MD_MS', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MD_MS.fee}
                      onChange={(e) => handleChange('MD_MS', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('PG', newFeeStructure.PG.min_students, newFeeStructure.PG.max_students, newFeeStructure.PG.fee)}>
                      Create New PG Fee Structure
                    </Button> */}
          <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate('MD_MS', newFeeStructure.MD_MS.min_students, newFeeStructure.MD_MS.max_students, newFeeStructure.MD_MS.fee)}
            sx = {{whiteSpace: 'nowrap'}}
          >
           Create New 
          </Button>
        </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddNewFeeStructure('MD_MS')}
          >
           Add New 
          </Button>
        </Box>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <Typography variant="h6" gutterBottom>MDS</Typography>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.MDS.length > 0 ? (
                feeStructures.MDS.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('MDS', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('MDS', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('MDS', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('MDS', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('MDS', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for PG category.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for PG */}
              {newFeeStructure.MDS && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MDS.min_students}
                      onChange={(e) => handleChange('MDS', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MDS.max_students}
                      onChange={(e) => handleChange('MDS', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.MDS.fee}
                      onChange={(e) => handleChange('MDS', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('PG', newFeeStructure.PG.min_students, newFeeStructure.PG.max_students, newFeeStructure.PG.fee)}>
                      Create New PG Fee Structure
                    </Button> */}
          <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate('MDS', newFeeStructure.MDS.min_students, newFeeStructure.MDS.max_students, newFeeStructure.MDS.fee)}
            sx = {{whiteSpace: 'nowrap'}}
          >
           Create New 
          </Button>
        </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* <Button onClick={() => handleAddNewFeeStructure('PG')}>Add New PG Fee Structure</Button> */}
          <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddNewFeeStructure('MDS')}
          >
           Add New 
          </Button>
        </Box>

        </Grid>
      </Grid>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );


};
export default ConsentFeeStructure;