

// import React, { useContext, useState, useEffect } from "react";
// // import { assessorsData } from './dropDownOptions'; // Adjust the import path
// import axios from "axios";
// import Master from "./Master";
// import './SelectAssessor.css';

// const SelectAssessor = () => {
//   const [selectedAssessor, setSelectedAssessor] = useState(null);
//   const [selectedAssessoremail, setSelectedAssessoremail] = useState(null);
//   const [assessorsdata,setassessorsdata]=useState([])
//   const { baseurl, collegrequestedocs, setcollegrequestedocs, roleid,selectedrequestobject
//     ,setselectedrequestobject
//   } = useContext(Master);

//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [disablassignassessorbutton,setdisablassignassessorbutton]=useState(true);
//   const [displaysuccessmessage,setdisplaysuccessmessage]=useState("");
//   const [assignedassor,setassignedassor]=useState([])

//   const handleRowSelect = (id,email) => {
//     setSelectedAssessor(id); // Set the selected assessor; deselect others
//     setSelectedAssessoremail(email);
//     setdisablassignassessorbutton(false);
//   };


//   function handlemassignaccessor() {



//     axios({
//       url: baseurl+"/assignassessortask",
//       method: "POST",
//       headers: {
//           authorization: `Bearer ${localStorage.getItem("token")}` ,
//       },
   
//         data: {
//           affiliationrequestid:selectedrequestobject.requestid,
//           assessoremail:selectedAssessoremail
//   }
  
  
      
//   }
//   )
//       .then((response) => {         
//           if(response.data.success){
//             setSuccessMessage(true);
//             setErrorMessage(false);
//            setdisplaysuccessmessage("Assessor assigned successfully");
            
//             setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
//             setdisablassignassessorbutton(true);
  
//           }
  
//       })
//       .catch((error) => {
//         setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
//         setSuccessMessage(false);
//         setErrorMessage(true);
//         setdisplaysuccessmessage("Unexpected Error");
//         setdisablassignassessorbutton(false);

//       });
//   }

  


//   useEffect(() => {




//     axios({
//       url: baseurl + "/getassessorreport",
//       method: "POST",
//       headers: {
//         authorization: `Bearer ${localStorage.getItem("token")}`,
//       },

//       data: {
//         affiliationrequestid: selectedrequestobject.requestid,
//       }

//     }
//     )
//       .then((response) => {
//         if (response.data.success) {


//           if (response.data.data[0].status == 1) {
        

//             setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: true }))
//           } else {
//             setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: false }))
//           }

//         } else {

//           setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: false }))

//         }

//       })
//       .catch((error) => {

//       });


//   },[])


//     useEffect(() => {
//     const fetchAssessors = async () => {
//       try {
//         // 
//         const response = await axios.get(baseurl+'/assessors');
//         setassessorsdata(response.data.assessors); // Save fetched assessors in state
        
//       } catch (error) {
//         console.error('Error fetching assessors:', error);
//       }
//     };

//     fetchAssessors();

//   }, []); // Empty array ensures it runs only once when the component mounts




// useEffect(() => {

//   axios({
//     url: baseurl + "/assessor/assigned/ + selectedrequestobject.requestid ",
//     method: "POST",
//     headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`,
//     },

//     data: {
//       affiliationrequestid: selectedrequestobject.requestid,
//     }

//   }
//   )
//     .then((response) => {


//       if (response.data.success) {
      

//         if (response.data.data.length == 1) {
      
//        // important

//           setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
//           setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: false }))
//           setassignedassor(response.data.data)
          
          
//         } 
        
//         if (response.data.data.length == 0)  {
//           setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
//           setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: true }))
//           setassignedassor([])
//         }


//         if(response.data.data.length >1) {
//           setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
//           setErrorMessage(true);
//           setdisplaysuccessmessage("More than one assessor assigned to this request, Please Contact admin");
//         }

//       } 
      

      
//       else {

//         setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
//         setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: false }))

//       }

//     })
//     .catch((error) => {

//     });


// },[])



  

//   return (

//     <>
//   {roleid==process.env.REACT_APP_ADMIN && <>
//     <div className="selectaccessor">
      
//       <h3>Select Assessor</h3>
//       <table border="1">
//         <thead>
//           <tr>
//             <th>Select</th>
//             <th>Name</th>
//             <th>Designation</th>
//             <th>Years of Experience</th>
//             <th>College Name</th>
//             <th>Email</th>
//           </tr>
//         </thead>
//         <tbody>
//           {assessorsdata.map((assessor) => (
//             <tr key={assessor.id}>
//               <td>
//                 <input
//                   type="checkbox" 
//                   name="assessorSelection" 
//                   checked={selectedAssessor === assessor.id}
//                   onChange={() => handleRowSelect(assessor.id,assessor.email)}
//                 />
//               </td>
//               <td>{assessor.name}</td>
//               <td>{assessor.designation}</td>
//               <td>{assessor.yearsofexperience}</td>
//               <td>{assessor.collegename}</td>
//             <td>{assessor.email}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>


//       {/* {selectedAssessor && (
//         <div>
//           <h4>Selected Assessor:</h4>
//           <p>
//             ID: {selectedAssessor} - Name: {
//               assessors.find((assessor) => assessor.id === selectedAssessor)?.name
//             }
//           </p>
//         </div>
//       )} */}

//         <br/> <br/>
// {(roleid == process.env.REACT_APP_ADMIN  && assignedassor.length==0/* ((selectedrequestobject).showassessorreport) */  /* &&  selectedrequestobject.status==0 */) &&
//         <div >
//           <button className="greenbutton"  onClick={handlemassignaccessor}
//              disabled={disablassignassessorbutton}
//             // className="uploadfilesumitbtn"
//           >Assign Assessor</button>
//         </div>
//       }

//           {successMessage && (
//     <p className="successmessage">{displaysuccessmessage}</p>
// )}
// {errorMessage&&(
//   <p className="errormessage">{displaysuccessmessage}</p>
// )}
      
//     </div>


//     </>
// }
//     </>
//   );
// };

// export default SelectAssessor;

// /////////////////////////////////////////////////////////////


import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Master from "./Master";
import './SelectAssessor.css';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, CircularProgress } from '@mui/material';

const SelectAssessor = () => {
  const [selectedAssessor, setSelectedAssessor] = useState(null);
  const [selectedAssessoremail, setSelectedAssessoremail] = useState(null);
  const [assessorsdata, setAssessorsdata] = useState([]);
  const {isloading,setisloading, baseurl, collegrequestedocs, setcollegrequestedocs, roleid, selectedrequestobject, setselectedrequestobject } = useContext(Master);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disablassignassessorbutton, setDisablassignassessorbutton] = useState(true);
  const [displaysuccessmessage, setDisplaysuccessmessage] = useState("");
  const [assignedassor, setAssignedassor] = useState([]);

  const handleRowSelect = (id, email) => {
    setSelectedAssessor(id); // Set the selected assessor; deselect others
    setSelectedAssessoremail(email);
    setDisablassignassessorbutton(false);
  };

  const handleAssignAssessor = () => {
    setisloading(true);
    axios({
      url: baseurl + "/assignassessortask",
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        affiliationrequestid: selectedrequestobject.requestid,
        assessoremail: selectedAssessoremail
      }
    })
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage(true);
          setErrorMessage(false);
          setDisplaysuccessmessage("Assessor assigned successfully");

          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }));
          setDisablassignassessorbutton(true);
          setisloading(false);
        }
      })
      .catch((error) => {
        setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }));
        setSuccessMessage(false);
        setErrorMessage(true);
        setDisplaysuccessmessage("Unexpected Error");
        setDisablassignassessorbutton(false);
        setisloading(false);
      });
  };

  useEffect(() => {
    const fetchAssessors = async () => {
      try {
        const response = await axios.get(baseurl + '/assessors');
        setAssessorsdata(response.data.assessors); // Save fetched assessors in state
      } catch (error) {
        console.error('Error fetching assessors:', error);
      }
    };
    fetchAssessors();
  }, []); // Empty array ensures it runs only once when the component mounts

  useEffect(() => {
    axios({
      url: baseurl + "/assessor/assigned/" + selectedrequestobject.requestid,
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        affiliationrequestid: selectedrequestobject.requestid,
      }
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.length === 1) {
            setSelectedAssessoremail(response.data.data[0].email);
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }));
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: false }));
            setAssignedassor(response.data.data);
          }
          if (response.data.data.length === 0) {
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }));
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: true }));
            setAssignedassor([]);
          }
          if (response.data.data.length > 1) {
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }));
            setErrorMessage(true);
            setDisplaysuccessmessage("More than one assessor assigned to this request, Please Contact admin");
          }
        } else {
          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }));
          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: false }));
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      {roleid === parseInt(process.env.REACT_APP_ADMIN) && (
        <>
          <div className="selectaccessorwrapper">
          <div className="component-header"> 
          {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> &nbsp; */}

 Assessor allotment

            
             </div>
            {/* <TableContainer component={Paper}> */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Years of Experience</TableCell>
                    <TableCell>College Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessorsdata.map((assessor) => (
                    <TableRow key={assessor.id} 
                    sx={{
                      border: selectedAssessoremail === assessor.email ? '3px solid green' : '',
                    }}
                    >
                      <TableCell>
                        <input
                          type="checkbox"
                          name="assessorSelection"
                          checked={selectedAssessoremail === assessor.email}
                          onChange={() => handleRowSelect(assessor.id, assessor.email)}
                          disabled={assignedassor.length > 0}
                        />
                      </TableCell>
                      <TableCell>{assessor.name}</TableCell>
                      <TableCell>{assessor.designation}</TableCell>
                      <TableCell>{assessor.yearsofexperience}</TableCell>
                      <TableCell>{assessor.collegename}</TableCell>
                      <TableCell>{assessor.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            {/* </TableContainer> */}

            {roleid === parseInt(process.env.REACT_APP_ADMIN) && assignedassor.length === 0 && (
              <div>
                <Button
                sx = {{marginTop:"20px"}}
                  variant="contained"
                  color="primary"
                  onClick={handleAssignAssessor}
                  disabled={disablassignassessorbutton}
                >
                  Assign Assessor
                </Button>
              </div>
            )}

            {successMessage && (
              <Typography variant="body1" color="primary" className="successmessage">
                {displaysuccessmessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography variant="body1" color="error" className="errormessage">
                {displaysuccessmessage}
              </Typography>
            )}
          </div>
        </>
      )}

      {isloading && (
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1300,
                }}
              >
                <CircularProgress />
              </Box>
            )}
    </>
  );
};

export default SelectAssessor;



