import axios from "axios";
import "../../Css/MenuBar/GST.css";
import React, { useContext, useEffect, useState } from "react";

import {
  Snackbar,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import Master from "../../Master";

export default function GST() {
  const MasterCxt = useContext(Master);

  const [gst, setGst] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // New state for tracking loading
  const [severity, setSeverity] = useState("success"); // To determine the message color (success/error)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Retrieve the Bearer token (adjust as needed depending on where you store the token)

  const token = localStorage.getItem("token"); // Example: Retrieving token from localStorage

  // Fetch current GST value from the server when the component mounts

  useEffect(() => {
    const fetchGst = async () => {
      setLoading(true); // Set loading to true while fetching data

      try {
        const response = await axios.get(MasterCxt.baseurl + "/gst", {
          headers: {
            Authorization: `Bearer ${token}`, // Include Bearer token in the header
          },
        });

        if (response.data.success) {
          if (response.data.data.length > 0) {
            setGst(response.data?.data[0]?.gst);
          } else {
            setMessage("GST not yet set, Please insert GST rate");
          }

          // Set the fetched GST value into state
        } else {
          setMessage("Error fetching GST");
        }
      } catch (error) {
        console.error("Error fetching GST:", error);

        setSeverity("error");
        setOpenSnackbar(true);
        setMessage("Error fetching GST");
      } finally {
        setLoading(false); // Set loading to false when the request completes
      }
    };

    fetchGst(); // Call the fetch function on component mount
  }, [token]); // Dependency array ensures the effect runs only once when the component mounts

  // Handle change in GST input (ensure it's a valid number)

  const handleGstChange = (event) => {
    const value = event.target.value;

    // Ensure value is a valid number or empty string

    if (/^\d*\.?\d*$/.test(value)) {
      setGst(value);
    }
  };

  // Handle blur or mouseout event to make axios call to update GST on the server

  const handleGstBlur = () => {
    // Ensure gst is a valid number before sending the request

    if (gst !== "" && !isNaN(gst)) {
      setLoading(true); // Set loading to true while sending data

      // Trigger the axios call to update the database with Bearer token

      axios
        .post(
          MasterCxt.baseurl + "/gst",
          { gst: parseFloat(gst) }, // Ensure gst is sent as a number
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include Bearer token in the header
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setOpenSnackbar(true); // Show success message
            setSeverity("success");
            setMessage("GST updated successfully!");
          } else {
            setSeverity("error");
            setOpenSnackbar(true); // Show error message
            setMessage("Error updating GST!");
          }
        })
        .catch((error) => {
          setSeverity("error");
          setOpenSnackbar(true); // Show error message
          setMessage("Error updating GST!");
        })
        .finally(() => {
          setLoading(false); // Set loading to false when the request completes
        });
    } else {
      setSeverity("error");
      setOpenSnackbar(true); // Show error message
      setMessage("Please enter a valid number for GST.");
    }
  };

  // Handle closing Snackbar

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="gstMainContainer">
      {/* Centered Loading Spinner */}

      {loading && (
        <div
          style={{
            zIndex: 1,
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="gstHeader">Enter GST Percentage</div>
      <TextField
        label="GST"
        value={gst}
        variant="outlined"
        onBlur={handleGstBlur}
        className="gstTextField"
        onChange={handleGstChange}
        onMouseOut={handleGstBlur}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        inputProps={{
          min: 0,
        }}
      />

      {/* Snackbar for success/error messages */}

      <Snackbar
        message={message}
        open={openSnackbar}
        severity={severity}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </div>
  );
}
