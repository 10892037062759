import React, { useContext, useState, useEffect } from "react";
import Textarea from "./elements/textarea/Textarea";
import "./Assessorreport.css";
import axios from "axios";
import Master from "./Master";

function Assessorreport() {
  const {
    baseurl,
    collegrequestedocs,
    setcollegrequestedocs,
    roleid,
    userid,
    newaffiliationrequest,
    setnewaffiliationrequest,
    affiliationrequestid,
    setaffiliationrequestid,
    setaccessorrequestdocs,
    accessorrequestdocs,
    selectedrequestobject,
    setselectedrequestobject,
    isloading,
    setisloading,
    successmessage,
    setsuccessmessage,
    errormessage,
    seterrormessage,
  } = useContext(Master);
  const [disablsubmitreportbutton, setdisablsubmitreportbutton] =
    useState(true);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handlefilechange = async (e, fileid, index) => {
    const updateddata = [...accessorrequestdocs];
    updateddata[index].file = e.target.files[0];
    updateddata[index].fileselected = true;
    updateddata[index].uploadstatus = "File Selected but not uploaded";
    updateddata[index].approvalstatus = "Action Pending";
    updateddata[index].comment = "";
    setaccessorrequestdocs(updateddata);
  };

  function handledownloadsubmittedfile(fileid, index) {
    axios({
      url: baseurl + `/files/download/${fileid}`,
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      // data: {}
      params: {
        affiliationrequestid: selectedrequestobject.requestid,
      },
    })
      .then((response) => {
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', accessorrequestdocs[index].file_name);
        // document.body.appendChild(link);
        // link.click();

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a link element to trigger file download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = accessorrequestdocs[index].file_name; // Use the file name from collegrequestedocs
        document.body.appendChild(link);
        link.click();
        link.remove(); // Clean up the link element
      })
      .catch((error) => {
        // alert("Unexpected Error!")
      });
  }

  function handlefileapprovalstatus(index, approvalstatus) {
    axios({
      url: baseurl + "/files/status",
      method: "PUT",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        affiliationrequestid: selectedrequestobject.requestid,
        userid: parseInt(userid),
        fileid: accessorrequestdocs[index].id,
        status: parseInt(accessorrequestdocs[index].documentapprovalstatus),
        comment: accessorrequestdocs[index].rejectedcomment,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setsuccessmessage(response.data.message);
        } else {
          seterrormessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Error updating request status:",
          error.response?.data || error.message
        );
        seterrormessage(error.data.message);
      });
  }

  const handletextareachange = (index, comment) => {
    const updateddata = [...accessorrequestdocs];
    updateddata[index].rejectedcomment = comment;
    setaccessorrequestdocs(updateddata);
  };
  const handleradiochange = (index, value) => {
    const updateddata = [...accessorrequestdocs];
    updateddata[index].documentapprovalstatus = value;

    setaccessorrequestdocs(updateddata);
  };

  function handleFileUpload(id, index) {
    if (!selectedFile) {
      setMessage("Please select a file first.");
      return;
    }
    // Prepare your form data or any necessary data for the request

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileid", 5);
    formData.append("affiliationrequestid", selectedrequestobject.requestid);
    formData.append("newaffiliationrequest", newaffiliationrequest);
    formData.append("userid", userid);
    formData.append("roleid", roleid);
    formData.append("status", 0); // Adjust based on your API requirements

    // Send the request
    axios({
      url: baseurl + "/files/upload",
      formData,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setsuccessmessage(response.data.message);
          setdisablsubmitreportbutton(true);
          // setaccessorrequestdocs(response.data);
        } else {
          //throw new Error("Failed to update request status");
          seterrormessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Error updating request status:",
          error.response?.data || error.message
        );
        setdisablsubmitreportbutton(true);
        seterrormessage(error.data.message);
      });

    ///////////////////////////////////////////////////////
  }
  useEffect(() => {
    //setisloading(true);
    axios({
      url: baseurl + "/doc/assessment/" + selectedrequestobject.requestid,
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        
        
        if (response.data.success) {
          var responsedata = response.data.data.assessordoc[0];

          responsedata.forEach(function (element) {
            element.file = null;
            element.fileselected = false;
            element.filechanged = false;
            element.fileuploaded = false;
            element.uploadsuccess = false;
            element.uploadstatus = " File not selected";
            element.documentactionstatus = "";
            element.approvalstatus = "Action Pending";
            element.comment = "";
            element.rejectedcomment = "";
            element.uploadeddocumentstatus = "Action Pending";
          });

          setaccessorrequestdocs(responsedata);

          if (response.data.data.requestassessmenttdoc.length == 0) {
            setselectedrequestobject((prevdata) => ({
              ...prevdata,
              paymentaffiliation: false,
            }));
          }
          if (response.data.data.requestassessmenttdoc.length > 1) {
            if (response.data.data.requestassessmenttdoc[0].status == null) {
              setselectedrequestobject((prevdata) => ({
                ...prevdata,
                paymentaffiliation: true,
              }));
              setdisablsubmitreportbutton(true);
            }
          }
        }
      })
      .catch((error) => {
        setselectedrequestobject((prevdata) => ({
          ...prevdata,
          paymentaffiliation: false,
        }));
      })
      .finally(() => {
        setisloading(false);
      });
  }, []);

  // const createdownloadlink = (file) => {
  //   if (file instanceof File) {
  //     return URL.createObjectURL(file);
  //   }
  //   return '';
  // };

  return (
    <div>
      <div className="fileUploads">
        {/* File Upload Sections */}
        {accessorrequestdocs?.map(
          (
            {
              documentactionstatus,
              documentapprovalstatus,
              rejectedcomment,
              uploadstatus,
              approvalstatus,
              id,
              name,
              haslink,
              link,
              comment,
              accept = ".pdf,.docx,.doc",
            },
            index
          ) => (
            <div key={name} className="file-upload">
              <div className="uploadlabel">
                <label>Upload {name}</label>
              </div>
              {roleid == process.env.REACT_APP_ASSESSOR && (
                <input
                  type="file"
                  accept={accept}
                  onChange={(e) => handlefilechange(e, id, index)}
                />
              )}
              <div className="downloadbuttons">
                {haslink == 1 && roleid == process.env.REACT_APP_ASSESSOR && (
                  <>
                    <a href={link} target="_blank" download>
                      <button className="templatedownload">
                        Download Template
                      </button>
                    </a>
                    {/* {fileErrors[`${name}error`] && <div className="error">{fileErrors[`${name}error`]}</div>} */}
                  </>
                )}
                {/* {formData[name] && (
              <a href={createdownloadlink(formdata[name])} download>
                <button className="formdownload">Download Form</button>
              </a>
            )} */}

                {Object.keys(selectedrequestobject).length > 0 && (
                  <>
                    <button
                      className="formdonload"
                      onClick={() => handledownloadsubmittedfile(id, index)}
                    >
                      Download Form
                    </button>
                  </>
                )}
              </div>

              {/* {( roleid==process.env.REACT_APP_ADMIN) &&
          <div className="formradioinput">
            <input type="radio" name={`upload_${name}`} value="1" checked={formdata[`upload_${name}`] === "1"} onChange={(e) => handleradiochange(`upload_${name}`, e.target.value)} />
            <label>Approved</label>
            <input type="radio" name={`upload_${name}`} value="0" checked={formdata[`upload_${name}`] === "0"} onChange={(e) => handleradiochange(`upload_${name}`, e.target.value)} />
            <label>Comments</label>
          </div>
      }
          {formdata[`upload_${name}`] === "0" && (
            <Textarea
              placeholder={`Add comments for ${name}`}
              name={comment}
              value={formdata[comment]}
              update={(value) => handletextareachange(comment, value)}
            />
          )}
        </div>
      ))} */}

              {roleid == process.env.REACT_APP_ADMIN &&
                Object.keys(selectedrequestobject).length > 0 &&
                selectedrequestobject.status !== 1 && (
                  <div className="formradioinput">
                    <input
                      type="radio"
                      name={name}
                      value="1"
                      checked={documentapprovalstatus === "1"}
                      onChange={(e) => handleradiochange(index, e.target.value)}
                    />
                    <label>Approved</label>
                    <input
                      type="radio"
                      name={name}
                      value="0"
                      checked={documentapprovalstatus === "0"}
                      onChange={(e) => handleradiochange(index, e.target.value)}
                    />
                    <label>Request more information</label>
                  </div>
                )}
              {documentapprovalstatus === "0" && (
                <Textarea
                  placeholder={`Add comments for ${name}`}
                  name={name}
                  value={rejectedcomment}
                  update={(value) => handletextareachange(index, value)}
                />
              )}
              <div className="downloadbuttons">
                {(documentapprovalstatus === "1" ||
                  documentapprovalstatus === "0") && (
                  <>
                    <button
                      className={
                        documentapprovalstatus == "1"
                          ? "greenbutton"
                          : "orangebutton"
                      }
                      onClick={() => handlefileapprovalstatus(index)}
                    >
                      {documentapprovalstatus === "1"
                        ? "Confirm Approval"
                        : "Send Reupload Email"}
                    </button>
                    <br />
                    <span>
                      {" "}
                      Status:{" "}
                      {documentactionstatus == ""
                        ? "Action Pending"
                        : documentactionstatus}{" "}
                    </span>
                  </>
                )}
              </div>
            </div>
          )
        )}
      </div>
      {roleid == process.env.REACT_APP_ASSESSOR && (
        <div className="uploadfilesubmit">
          <button
            onClick={handleFileUpload}
            disabled={disablsubmitreportbutton}
            className="uploadfilesumitbtn"
          >
            Submit Report
          </button>
        </div>
      )}

      {successmessage && <p className="successmessage">{successmessage}</p>}
      {errormessage && <p className="errormessage">{errormessage}</p>}
    </div>
  );
}
export default Assessorreport;
