import React from "react";

export function UploadSvgIcon() {


    return(


        <svg
         width="17" 
         height="18"
          viewBox="0 0 17 18" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          >
<path d="M16 11.5V14.8333C16 15.2754 15.8244 15.6993 15.5118 16.0118C15.1993 16.3244 14.7754 16.5 14.3333 16.5H2.66667C2.22464 16.5 1.80072 16.3244 1.48816 16.0118C1.17559 15.6993 1 15.2754 1 14.8333V11.5M12.6667 5.66667L8.5 1.5M8.5 1.5L4.33333 5.66667M8.5 1.5V11.5" stroke="#667085" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
};