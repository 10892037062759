// export const options = [
//   {
//     value: 1,
//     label: "Government",
//   },
//   {
//     value: 2,
//     label: "Society",
//   },
//   {
//     value: 3,
//     label: "Trust",
//   },
//   {
//     value: 4,
//     label: "Company",
//   },
//   {
//     value: 5,
//     label: "Autonomous Body",
//   },
//   {
//     value: 6,
//     label: "Consortium",
//   },
// ];

// export const academicYearOptions = [
//   { label: "2024", value: "2024" },
//   { label: "2025", value: "2025" },
//   { label: "2026", value: "2026" },
// ];
// export const affiliationTypeOptions = [
//   { label: "Consent of Affiliation", value: "consent" },
//   { label: "Provisional Affiliation", value: "provisional" },
//   { label: "Continuation/Renewal Affiliation", value: "renewal" },
//   // { label: "Type 1", value: "type1" },
// ];
// export const affiliationType = [
//   { value: "type1", label: "Type 1" },
//   { value: "type2", label: "Type 2" },
//   { value: "type3", label: "Type 3" },
// ];
// export const ugCoursesOptions = [
//   { label: "B.Sc", value: "bsc" },
//   { label: "B.Com", value: "bcom" },
//   { label: "B.A", value: "ba" },
// ];

// export const pgCoursesOptions = [
//   { label: "M.Sc", value: "msc" },
//   { label: "M.Com", value: "mcom" },
//   { label: "M.A", value: "ma" },
// ];
// // export  const baseUrl = "https://localhost:8000";
// export const courses = ["MBBS", "BDS", "Nursing", "Pharmacy"];
// export const subOptions = [
//   { value: "newCollege", label: "Starting a New College" },
//   { value: "newCourses", label: "Adding New Courses" },
//   { value: "increaseSeats", label: "Increasing Seat Numbers" },
// ];

// const ISSUE_STATUS = {
//   PENDING: 1,
//   RESOLVED: 2,
// };

const HEADER = {
  PROFILE: 1,
  SETTINGS: 2,
};

const MENUBAR = {
  GST: 4,
  FEE: 6,
  BANK: 7,
  BACK: 1,
  CLGCODE: 3,
  COURSES: 5,
  ACADEMIC: 2,
};

const PROFILE = {
  EDIT: 1,
  PHONE: 2,
  EMAIL: 4,
  WEBSITE: 3,
  ADDRESS: 7,
  LOCATION: 9,
  MANAGEMENT: 5,
  COLLEGECODE: 8,
  REGISTRATION: 6,
};

const AFFILIATION = {
  CONSENT: 1,
  RENEWAL: 3,
  PROVISIONAL: 2,
};

module.exports = {
  HEADER,
  MENUBAR,
  PROFILE,
  AFFILIATION,
};
