// import Button from "../Button";
import Master from "../../Master";
import constants from "../../constants";
import CourseSelector from "./CourseSelector";
import CollegeTableUG from "./CollegeTableUG";
import CollegetablePGMDS from "./CollegetablePGMDS";
import CollegeTablePGMDMS from "./CollegeTablePGMDMS";
import AffiliationSvgIcon from "../Svg/AffiliationSvgIcon";
import "../../Css/RequestDetailPage/AffiliationOptions.css";
import React, { useContext, useEffect, useState } from "react";

import {
  Radio,
  Alert,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Button
} from "@mui/material";
import UploadCollegeFiles from "./UploadCollegeFiles";

export default function AffiliationOptions() {
  const MasterCxt = useContext(Master);

  const [selectedparent, setselectedparent] = useState(null);
  const [selectedchild, setselectedchild] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" | "error" | "info" | "warning"
  const [disableSubmit, setDisableSubmit] = useState(false);
  // Snackbar close handler


  

    useEffect(() => {
      if (MasterCxt.roleid == process.env.REACT_APP_ADMIN) {
        MasterCxt.setnewrequest(false);
        setDisableSubmit(true);
      }
  
      // if(MasterCxt.affiliationrequestid){
        
      //   setDisableSubmit(true);
      // }
      
  
    }, [MasterCxt.affiliationrequestid]);

    useEffect(() => {
        setDisableSubmit(MasterCxt.disablesaveandcontinue);
    }, [MasterCxt.disablesaveandcontinue]);


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleRadioChangeParent = (value) => {
    console.log("JAJAJAJAJAJAJAJA");
    
    MasterCxt.setpgsuboptions(null);
    MasterCxt.setselectedprogram(null);
    MasterCxt.setprovisionalsuboption("");
    setselectedparent(value);
    MasterCxt.setselectedparent(value);

    if (value === 0) {
    } else {
      setselectedchild(null);
    }
  };

  const handleRadioChangeChild = (event) => {
    setselectedchild(parseInt(event.target.value, 10));
    MasterCxt.setaffiliationtypeid(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    if (selectedparent === 0) {  
      if (selectedchild) {
        MasterCxt.setaffiliationtypeid(selectedchild);
      } else {
        MasterCxt.setaffiliationtypeid(selectedparent);
      }

      // Reset child selection when parent changes
    } else {
      MasterCxt.setaffiliationtypeid(selectedparent);
    }
  }, [selectedparent, selectedchild]);

  useEffect(() => {
    if (Object.keys(MasterCxt.selectedrequestobject).length > 0 ) {
      if (
        MasterCxt.selectedrequestobject.requesttypeid === 4 ||
        MasterCxt.selectedrequestobject.requesttypeid === 5
      ) {
        setselectedparent(MasterCxt.selectedrequestobject.requesttypeid);
      } else {
        setselectedparent(0);
        setselectedchild(MasterCxt.selectedrequestobject.requesttypeid);
      }
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  useEffect(() => {
    if (Object.keys(MasterCxt.selectedrequestobject).length > 0) {
      if (
        MasterCxt.selectedrequestobject.requesttypeid === 4 ||
        MasterCxt.selectedrequestobject.requesttypeid === 5
      ) {
        setselectedparent(MasterCxt.selectedrequestobject.requesttypeid);
      } else {
        setselectedparent(0);
        setselectedchild(MasterCxt.selectedrequestobject.requesttypeid);
      }

    }

    
  }, [MasterCxt.selectedrequestobject]);

  const isDisabled =
    MasterCxt.affiliationrequestid != null ||
    Object.keys(MasterCxt.selectedrequestobject).length > 0;
    

  return (
    <div className="affiliationOptionsMainContainer">
      <div
        className={`affiliationOptionsHeader ${isDisabled ? "disable" : ""}`}
      >
        <div
          onClick={() => handleRadioChangeParent(0)}
          className={`affiliationOptionsHeaderLabel ${
            selectedparent === 0 ? "active" : ""
          }`}
        >
          <AffiliationSvgIcon icon={constants.AFFILIATION.CONSENT} />
          Consent of Affiliation
        </div>
        <div
          onClick={() => handleRadioChangeParent(4)}
          className={`affiliationOptionsHeaderLabel ${
            selectedparent === 4 ? "active" : ""
          }`}
        >
          <AffiliationSvgIcon icon={constants.AFFILIATION.PROVISIONAL} />
          Provisional Affiliation
        </div>
        <div
          onClick={() => handleRadioChangeParent(5)}
          className={`affiliationOptionsHeaderLabel ${
            selectedparent === 5 ? "active" : ""
          }`}
        >
          <AffiliationSvgIcon icon={constants.AFFILIATION.RENEWAL} />
          Renewal Affiliation
        </div>
      </div>
      {selectedparent !== null && (
        <div className="affiliationOptionsBody">
          {selectedparent === 0 && (
            <div
              className={`affiliationOptionsType ${
                isDisabled ? "disable" : ""
              }`}
            >
              <span className="affiliationOptionsTypeLabel">For : </span>
              <RadioGroup
                row
                name="child"
                aria-label="child"
                value={selectedchild}
                onChange={handleRadioChangeChild}
                className="affiliationOptionsRadioGroup"
              >
                <FormControlLabel
                  value={1}
                  label={
                    <span className="affiliationOptionsRadioLabel">
                      Starting a new college
                    </span>
                  }
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />
                  }
                />
                <FormControlLabel
                  value={2}
                  label={
                    <span className="affiliationOptionsRadioLabel">
                      Adding new Courses
                    </span>
                  }
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />
                  }
                />
                <FormControlLabel
                  value={3}
                  label={
                    <span className="affiliationOptionsRadioLabel">
                      Increasing seat numbers
                    </span>
                  }
                  control={
                    <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />
                  }
                />
              </RadioGroup>
            </div>
          )}
          {MasterCxt.affiliationtypeid !== null &&
            MasterCxt.affiliationtypeid !== 0 && (
              <>
                <CourseSelector />
                {MasterCxt.selectedprogram=="UG" && <CollegeTableUG />}
                {MasterCxt.selectedprogram=="PG" && (
                  <>
                    {((MasterCxt.affiliationtypeid!=4 && MasterCxt.pgsuboptions=="MDS") ||
                      ( MasterCxt.affiliationtypeid==4 && MasterCxt.pgsuboptions=="MDS" && MasterCxt.provisionalsuboption!="" ) )
                    && <CollegetablePGMDS />}
                    {MasterCxt.pgsuboptions=="MD_MS" && <CollegeTablePGMDMS />}
                  </>
                )}
              </>
            )}

          {/* {(MasterCxt.affiliationrequestid != null ||
            MasterCxt.selectedrequestobject.requestid != null) &&
            (MasterCxt.pgsuboptions.MD_MS ||
              MasterCxt.pgsuboptions.MDS ||
              MasterCxt.selectedprogram.UG) && <UploadCollegeFiles />} */}

          {
          // Object.keys(MasterCxt.selectedrequestobject).length == 0 &&

            MasterCxt.roleid === parseInt(process.env.REACT_APP_COLLEGE) &&
            ((Object.keys(MasterCxt.selectedrequestobject).length == 0) ||
             (Object.keys(MasterCxt.selectedrequestobject).length > 0 && MasterCxt.selectedrequestobject.status==null)) &&
            (MasterCxt.pgsuboptions=="MD_MS" ||
              MasterCxt.pgsuboptions == "MDS" ||
              MasterCxt.selectedprogram == "UG") && (
              <div className="affiliationOptionsButtonDiv">
                <Button
                  // className="success"
                  color="success"
                  variant="contained"
                  // buttonText="Save & Continue"
                  onClick={MasterCxt.handlerequestcourses}
                  disabled={disableSubmit}
                >
                  Save & Continue
                  </Button>
              </div>
            )}
        </div>
      )}

      {/* Loading Overlay */}
      {MasterCxt.isloading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}

      {/* Snackbar for Notifications */}
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
    </div>
  );
}
