import React, { useContext, useEffect, useState } from 'react';
import styles from './Footer.module.css';
import Maincontent from './Maincontent';
import UserLogin from './UserLogin';
import Master from './Master';
import RegistrationForm from './RegistrationForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CollegeProfile from './CollegeProfile';
import Configuration from './Configuration';
import Login from './UserLogin';
import Header from './Header'
import './Layout.css';
import Profile from './Components/RequestDetailPage/Profile';
import AcademicYearInput from './AcademicYearInput';
import Sidebar from './Sidebar';
import Courseconfigurationug from './Courseconfigurationug';
import Courseconfigurationpgmdms from './Courseconfigurationpgmdms';
import Courseconfigurationpgmds from './Courseconfigurationpgmds';
import Collegetableug from './Collegetableug';
import Collegetablepgmdms from './Collegetablepgmdms';
import Collegetablepgmds from './Collegetablepgmds';
import Programselector from './Programselector';
import Affiliationoptions from './Affiliationoptions';
import UploadCollegeFiles from './UploadCollegeFiles';
import Assessorreport from './Assessorreport';
import SelectAssessor from './SelectAssessor';
import Finalcertificate from './Finalcertificate';
import Paymentassessor from './Paymentassessor'
import Paymentprovisional from './Paymentprovisional';
import BigFileUpload from './BigFileUpload';
import Requestlist from './Requestlist';
import { useNavigate } from "react-router-dom";
import Paymentaffiliation from './Paymentaffiliation';
import Courseconfiguration from './Courseconfiguration';
import Assor from './Assor';
import Collegecode from './Collegecode';
import Gstinput from './Gstinput';
import Universitybankcode from './Universitybankcode';
import AffiliationFeeStructure from './AffiliationFeeStructure';
import AssessorFeeStructure from './AssessorFeeStructure';
import Bankdetails from './Bankdetails';
//import AcademicYear from './AcademicYear';
import ConsentFeeStructure from './ConsentFeeStructure';
import ContinuationFeeStructure from './ContinuationFeeStructure';
import Academicyeardisplay from './Academicyeardisplay';
import NewLayout from './NewLayout';
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AcademicYearDisplay from './Components/RequestDetailPage/AcademicYearDisplay';
import AffiliationOptions from './Components/RequestDetailPage/AffiliationOptions';
import CourseSelector from './Components/RequestDetailPage/CourseSelector';
import CollegeTableUG from './Components/RequestDetailPage/CollegeTableUG';
import CollegeTablePGMDMS from './Components/RequestDetailPage/CollegeTablePGMDMS';
import CollegetablePGMDS from './Components/RequestDetailPage/CollegetablePGMDS';
import MenuBarSvgIcon from "./Components/Svg/MenuBarSvgIcon";
import constants from "./constants";
// import Collegecoursetables from './Collegecoursetables';


const Workflow = () => {
  const navigate = useNavigate();
  const {
    setuserid, setroleid, roleid, userid, baseurl, setnewrequest, newrequest,
    affiliationtypeid, selectedprogram, pgsuboptions, setpgsuboptions,
    affiliationrequestid, selectedrequestobject, academicyearid,
    setselectedrequestobject, handlerequestcourses, updatedrequestcourses,
    affiliationpaymentcomplete, assessorpaymentcomplete,MasterCxt,setdisablesaveandcontinue,
    showuploadfilessection,setshowuploadfilessection,setaffiliationtypeid,setaffiliationrequestid,
  } = useContext(Master);


  

  const handleBack = () => {
    navigate("/requests");
    setshowuploadfilessection(false);
    setselectedrequestobject({})
    setaffiliationrequestid(null)
    setaffiliationtypeid(null)
    setdisablesaveandcontinue(true)
  };

  useEffect(() => {
    // if (roleid == process.env.REACT_APP_ADMIN) {
    //   setnewrequest(false);
    // }

    
    


  }, [affiliationrequestid]);

  
  console.log("WWWWWWWWWWWWWWWWWWWWW        ", selectedrequestobject);
  


  return (
    <div className="workflow">
      <>
        {

          (((roleid == process.env.REACT_APP_ADMIN) && selectedrequestobject.userid) ||
            ((roleid == process.env.REACT_APP_ASSESSOR) && selectedrequestobject.userid) ||
            (roleid == process.env.REACT_APP_COLLEGE)) &&
          (
            <>
             
             <div className="menuBarMainInnerDiv">
              <MenuBarSvgIcon icon={constants.MENUBAR.BACK}  onClick={handleBack} /></div>
              <Profile />
              <AcademicYearDisplay />
              
            </>
          )}

        {
          (
            Object.keys(selectedrequestobject).length == 0 && ((newrequest == null) || (newrequest == false))
          ) &&
          <>
            <Requestlist />
          </>
        }

        {
          (
            ((roleid == process.env.REACT_APP_ADMIN) && (typeof selectedrequestobject.requestid != 'undefined')) ||
            ((roleid == process.env.REACT_APP_ASSESSOR) && typeof selectedrequestobject.userid != 'undefined') ||
            ((roleid == process.env.REACT_APP_COLLEGE) && (newrequest == true || typeof selectedrequestobject.userid != 'undefined'))
          ) &&
          <>
            <AffiliationOptions />
            {
              (affiliationtypeid != null && affiliationtypeid != 0) &&
              <>

                {(
                  
                  // (affiliationrequestid != null) ||
                  // (
                  //   // selectedrequestobject.requestid != null
                  //   (Object.keys(MasterCxt.selectedrequestobject).length>0 && MasterCxt.selectedrequestobject.status!=null)

                  // )

                  showuploadfilessection || selectedrequestobject.status!=null
                
                ) &&
                  (pgsuboptions == "MD_MS" ||
                    pgsuboptions == "MDS" ||
                    selectedprogram == "UG") && 
                    <UploadCollegeFiles />}

                {/* {(selectedrequestobject.managementtypeid == 1 && selectedrequestobject.status == 1) &&
                  <>
                    <Finalcertificate />
                  </>

                } */}


              {
                  (selectedrequestobject.managementtypeid != 1) &&
                  <>
                    {((selectedrequestobject.status == 1) ||
                    (selectedrequestobject.requesttypeid >= 4 && selectedrequestobject.status !=null )
                  
                  ) &&
                      <>
                        <Paymentprovisional />
                      </>
                    }

                    {/* {assessorpaymentcomplete&& affiliationpaymentcomplete && 
                      <Finalcertificate />
                    } */}
                  </>
                }

                {/* {
                  (selectedrequestobject.managementtypeid != 1 &&
                    selectedrequestobject.requesttypeid == 4) &&
                  <>
                    {selectedrequestobject.status == 1 &&
                      <>
                        <Paymentprovisional />
                        <SelectAssessor />
                      </>
                    }
  
                    {selectedrequestobject.showassessorreport == true &&
                          <Assor />
                        }

                    {selectedrequestobject.showfinalcertificate == true && 
                      <Finalcertificate />
                    }
                  </>
                } */}


                {/* {
                  (selectedrequestobject.managementtypeid != 1 &&
                    selectedrequestobject.requesttypeid == 5) &&
                  <>
                    {selectedrequestobject.status == 1 &&
                      <Paymentaffiliation />
                    }
                    {affiliationpaymentcomplete && 
                      <Finalcertificate />
                    }
                  </>
                } */}

                {/* {
                  (selectedrequestobject.managementtypeid != 1 &&
                    (selectedrequestobject.requesttypeid == 1 ||
                       selectedrequestobject.requesttypeid == 2 ||
                        selectedrequestobject.requesttypeid == 3)) &&
                  <>

                    {selectedrequestobject.status == 1 && 
                      <>
                        <Paymentassessor />
                        <SelectAssessor />
                      </>
                    }
                    {selectedrequestobject.showassessorreport == true &&
               
                          <Assor />
                        }
                    {selectedrequestobject.paymentaffiliation == true &&
                      <Paymentaffiliation />
                    }

                    {
                      affiliationpaymentcomplete &&
                      <Finalcertificate />
                    }
                  </>
                } */}

              </>
            }
          </>

        }

      </>
    </div>
  );
};

export default Workflow;
