import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Master from "./Master";
import { useNavigate } from "react-router-dom";
import './Requestlist.css';
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Typography,
  TextField,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import DataTable from 'react-data-table-component';

const Requestlist = () => {
  const {
    userid,
    baseurl,
    setroleid,
    roleid,
    academicyearid,
    newrequest,
    setnewrequest,
    affiliationtypeid,
    setaffiliationtypeid,
    setaffiliationrequestid,
    setselectedrequestobject,
    isloading,
    setisloading,
    setuserid,
    setdisablesaveandcontinue,
  } = useContext(Master);
  
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [  filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    axios({
      url: baseurl + "/requests",
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        academicyearid: academicyearid
      }
    })
      .then((response) => {
        // if (response.data.success) {
        //   setData(response.data.data);
        //   setFilteredData(response.data.data);
        if (response.data.success) {
          const dataWithIndex = response.data.data.map((item, index) => ({
            ...item,
            index,
          }));
          setData(dataWithIndex);
          setFilteredData(dataWithIndex);
          // setMessage("Request list fetched successfully!");
          setMessageType("success");
        } else {
          setMessage("Failed to fetch request list.");
          setMessageType("error");
        }
      })
      .catch((err) => {
        setMessage("Your session has expired. Redirecting to login...");
        setMessageType("error");
        setOpenSnackbar(true);
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/login');
        }, 2000);
      })
      .finally(() => {
        setisloading(false);
        setOpenSnackbar(true);
      });
  }, [academicyearid, baseurl, roleid, userid, setisloading]);

  const handleSelectedRequest = (item) => {
    setnewrequest(false);
    setselectedrequestobject(item);
    setaffiliationrequestid(item.requestid);
    if (!item.status) {
      setnewrequest(true);
    }
    navigate(`/requests/${item.requestid}`);
  };

  const handleRequestChange = (type) => {
    setIsActive(type);
    if (type === "Consent") {
      setFilteredData(data.filter((item) => [1, 2, 3].includes(item.requesttypeid)));
    } else if (type) {
      setFilteredData(data.filter((item) => item.requesttypeid === type));
    } else {
      setFilteredData(data); 
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      setFilteredData(
        data.filter(
          (item) =>
            item.collegename.toLowerCase().includes(query) ||
            String(item.requestid).toLowerCase().includes(query)
        )
      );
    } else {
      setFilteredData(data); 
    }
  };

  
  const columns = [
    {
      name: 'Request ID',
      selector: row => row.requestid,
      className:'reqid',
      sortable: true,
      cell: row => 
        (
      <span
        className="reqiddata"
        onClick={() => handleSelectedRequest(row)} // Propagate the click
      >
        {row.requestid}
      </span>
    ),
      // <span className="reqiddata">{row.requestid}</span>,
    },
    {
      name: 'College Name',
      selector: row => row.collegename,
      className:'clgname',
      sortable: true,
      cell: row =>(
      <span
        className="clgnamedata"
        onClick={() => handleSelectedRequest(row)} // Propagate the click
      >
        {row.collegename}
      </span>
    ),
        //  <span className="clgnamedata">{row.collegename}</span>,
    },
    {
      name: 'Request Date',
      className:'reqdate',
      selector: row => moment(row.created_date).format("DD/MM/YYYY"),
      sortable: true,
      cell: row =>(
      <span
        className="reqdatedata"
        onClick={() => handleSelectedRequest(row)} // Propagate the click
      >
        {moment(row.created_date).format("DD/MM/YYYY")}
      </span>
    ),
        //  <span className="reqdatedata">{moment(row.created_date).format("DD/MM/YYYY")}</span>,
    },
    {
      name: 'Status',
      className: 'reqstatus',
      selector: row => row.status,
      cell: row => (
        <span className={getStatusClass(row.status)}
          onClick={() => handleSelectedRequest(row)} > 
          {getStatusText(row.status)}
        </span>
      ),
    },
  ];

 
  const getStatusText = (status) => {
    switch (status) {
      case 0:
      case 3:
      case 2:
        return 'Pending';
      case 1:
        return (<span>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					   <path d="M10 3L4.5 8.5L2 6" stroke="#027A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					  </svg>	Approved
        </span>);
      case 4:
        return (
          <span>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 3L3.5 9M3.5 3L9.5 9" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                       </svg>  Reject
          </span>
        );
      default:
        return 'Incomplete';
    }
  };


  const getStatusClass = (status) => {
    switch (status) {
      case 0:
      case 3:
      case 2:
        return 'pendingstatus';
      case 1:
        return 'acceptstatus';
      case 4:
        return 'rejectstatus';
      default:
        return 'incompletestatus';
    }
  };


  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#f4f4f4',
        color:'#667085',
      },
      firstCell: {
        style: {
          borderTopLeftRadius: '8px',
        },
      },
      lastCell: {
        style: {
          borderTopRightRadius: '8px', 
        },
      },
    },
    // cells: {
    //   style: {
    //     // padding: '10px',
    //   },
    // },
  };

  return (
    <div className="requestlistmainwrapper">
      <Box sx={{ padding: 3 }}>
        {roleid == process.env.REACT_APP_COLLEGE && (
          <Box sx={{ marginBottom: 2 }}>
            <Button
              className="requestbtn"
              variant="contained"
              color="primary"
              onClick={() => {
                setselectedrequestobject({});
                setnewrequest(true);
                navigate("/requests/new");
                // setdisablesaveandcontinue(false);
                setdisablesaveandcontinue(true);

              }}
            >
              New Request
            </Button>
          </Box>
        )}
        <div className="requestheading">
        {roleid == process.env.REACT_APP_COLLEGE && (
          <Typography variant="h5" gutterBottom className="headingcontent">
            My Requests
          </Typography>
        )}
        {roleid == process.env.REACT_APP_ADMIN && (
          <Typography variant="h5" gutterBottom className="headingcontent">
            Affiliation Requests
          </Typography>
        )}
        </div>

        {isloading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="requestlistData">
            <div className="requesttypecontentbar">
              <div className="reqestbtncontainer">
                <span
                  className={isActive === "Consent" ? "isreqtypebtn" : "reqtypebtn"}
                  onClick={() => handleRequestChange("Consent")}
                >
                  Consent
                </span>
                <span
                  className={isActive === 4 ? "isreqtypebtn" : "reqtypebtn"}
                  onClick={() => handleRequestChange(4)}
                >
                  Provisional
                </span>
                <span
                  className={isActive === 5 ? "isreqtypebtn" : "reqtypebtn"}
                  onClick={() => handleRequestChange(5)}
                >
                  Renewal
                </span>
              </div>
              <TextField
                className="searchbar"
                variant="outlined"
                placeholder="Search"
                value={searchQuery}
                fullWidth
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: ' #f9f9f9',
                    height: '32px',
                    width: '180px',
                    border: '1px solid #D0D5DD',
                    color: ' #667085',
                    justifyContent: 'space-between',
                    display: 'flex',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    textAlign: 'left',
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '10px 14px',
                  },
                  '&.MuiFormControl-root': {
                    width: '180px',
                  },
                  '&.MuiTextField-root': {
                    width: '180px',
                  }
                }}
              />
            </div>

            <DataTable
              // title="Requests"
              className="requestlisttable"
              columns={columns}
              data={filteredData}
              customStyles={customStyles}
              // onRowClicked={handleSelectedRequest}
              onRowClicked={(row) => handleSelectedRequest(row)}
              // pagination
              highlightOnHover
              pointerOnHover
              noDataComponent="No data available."
              conditionalRowStyles={[
                {
                  when: (row) => row.index % 2 == 0,
                  style: { backgroundColor: '#ffffff' },
                },
                {
                  when: (row) => row.index % 2 != 0,
                  style: { backgroundColor: '#f9f9f9' },
                },
              ]}
            />
          </div>
        )}

        {/* <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={messageType} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar> */}
      </Box>
    </div>
  );
};

export default Requestlist;
