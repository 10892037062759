import axios from "axios";
import Master from "../../Master";
import "../../Css/MenuBar/UniversityBankCode.css";
import React, { useContext, useEffect, useState } from "react";

import {
  Alert,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

export default function UniversityBankCode() {
  const MasterCxt = useContext(Master);

  const [message, setMessage] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [loading, setLoading] = useState(false); // State for tracking loading
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageType, setMessageType] = useState("success"); // To determine the message color (success/error)

  // Retrieve the Bearer token (adjust as needed depending on where you store the token)

  const token = localStorage.getItem("token"); // Example: Retrieving token from localStorage

  // Fetch current Bank Code value from the server when the component mounts

  useEffect(() => {
    const fetchBankCode = async () => {
      setLoading(true); // Set loading to true while fetching data

      try {
        const response = await axios.get(
          MasterCxt.baseurl + "/university/bank-code",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include Bearer token in the header
            },
          }
        );

        if (response.data.success) {
          setBankCode(response.data?.data[0]?.bankcode);
        } else {
          setMessage("Error fetching GST");
        }
      } catch (error) {
        setOpenSnackbar(true);
        setMessageType("error");
        setMessage("Error fetching Bank Code");
        console.error("Error fetching Bank Code:", error);
      } finally {
        setLoading(false); // Set loading to false when the request completes
      }
    };

    fetchBankCode(); // Call the fetch function on component mount
  }, [token]); // Dependency array ensures the effect runs only once when the component mounts

  // Handle change in Bank Code input

  const handleBankCodeChange = (event) => {
    setBankCode(event.target.value);
  };

  // Handle blur or mouseout event to make axios call to update Bank Code on the server

  const handleBankCodeBlur = () => {
    // Ensure bankCode is a valid text value before sending the request

    if ((bankCode + "").trim() !== "") {
      setLoading(true); // Set loading to true while sending data

      // Trigger the axios call to update the database with Bearer token

      axios
        .post(
          MasterCxt.baseurl + "/university/bank-code",
          { bankcode: (bankCode + "").trim() }, // Send the bank code as a string
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include Bearer token in the header
            },
          }
        )
        .then((response) => {
          setMessageType("success");
          setOpenSnackbar(true); // Show success message
          setMessage("Bank Code updated successfully!");
        })
        .catch((error) => {
          setOpenSnackbar(true); // Show error message
          setMessageType("error");
          setMessage("Error updating Bank Code!");
        })
        .finally(() => {
          setLoading(false); // Set loading to false when the request completes
        });
    } else {
      setMessageType("error");
      setOpenSnackbar(true); // Show error message
      setMessage("Please enter a valid Bank Code.");
    }
  };

  return (
    <div className="universityBankCodeMainContainer">
      {/* Centered Loading Spinner */}

      {loading && (
        <div
          style={{
            zIndex: 1,
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="universityBankCodeHeader">Enter Bank Code</div>
      <TextField
        value={bankCode}
        label="Bank Code"
        variant="outlined"
        onBlur={handleBankCodeBlur}
        onChange={handleBankCodeChange}
        onMouseOut={handleBankCodeBlur}
        className="universityBankCodeTextField"
      />

      {/* Snackbar for success/error messages */}

      <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          severity={messageType}
          sx={{ width: "100%" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
