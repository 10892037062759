import axios from "axios";
import Master from "../../Master";
import constants from "../../constants";
import "../../Css/RequestDetailPage/Profile.css";
import React, { useContext, useEffect } from "react";
import ProfileSvgIcons from "../Svg/ProfileSvgIcons";
import { Box, CircularProgress } from "@mui/material";

export default function Profile() {
  const MasterCxt = useContext(Master);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        let axiosuserid = MasterCxt.userid;

        if (MasterCxt.roleid == process.env.REACT_APP_ADMIN) {
          axiosuserid = MasterCxt.selectedrequestobject.userid;
        }

        axios({
          method: "GET",
          url: MasterCxt.baseurl + `/users/${axiosuserid}`,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((response) => {
            if (response.data.success) {
              const { collegeDetails, managementtype } = response.data.data;

              MasterCxt.setregistrationformvalidationdata(collegeDetails);
              MasterCxt.setmanagementtype(managementtype);
            } else {
              console.error("Failed to fetch profile data");
            }
          })
          .catch((error) => {
            console.error("Error during API call:", error);
          });
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        MasterCxt.setisloading(false);
      }
    };

    if (MasterCxt.userid) {
      fetchProfileData();
    }
  }, [
    MasterCxt.userid,
    MasterCxt.baseurl,
    MasterCxt.navigate,
    MasterCxt.setroleid,
    MasterCxt.setregistrationformvalidationdata,
  ]);

  if (MasterCxt.isloading) {
    return (
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="profileMainContainer">
      <div className="profileHeader">
        <div className="profileCollegeName">
          {MasterCxt.registrationformvalidationdata.collegename}
        </div>
        <ProfileSvgIcons icon={constants.PROFILE.EDIT} />
      </div>
      <div className="profileBody">
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.PHONE} />
            Phone
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.telephone}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.WEBSITE} />
            Website
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.websiteurl}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.EMAIL} />
            Email
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.email}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.MANAGEMENT} />
            Management
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.managementtype}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.REGISTRATION} />
            Registration
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.registrationnumber?.toUpperCase()}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.COLLEGECODE} />
            College Code
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.collegecode}
          </div>
        </div>
        <div className="profileBodyInnerDiv">
          <div className="profileBodyInnerHeader">
            <ProfileSvgIcons icon={constants.PROFILE.PHONE} />
            Principal No.
          </div>
          <div className="profileBodyInnerBody">
            {MasterCxt.registrationformvalidationdata.principalmobile}
          </div>
        </div>
      </div>

      <div className="profileBodyInnerDiv">
        <div className="profileBodyInnerHeader">
          <ProfileSvgIcons icon={constants.PROFILE.LOCATION} />
          Address
        </div>
        <div className="profileBodyInnerBody">
          {MasterCxt.registrationformvalidationdata.address}
        </div>
      </div>
    </div>
  );
}
