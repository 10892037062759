// // import React, { useContext, useState, useEffect } from 'react';
// // import Master from './Master';
// // import axios from 'axios';
// // import './AcademicYearInput.css';

// // const AcademicYearInput = () => {
// //   const {
// //     academicid,
// //     setacademicid,
// //     iscurrent,
// //     setiscurrent,
// //     baseurl,
// //     academicyearid, setacademicyearid,
// //     academicyear, setacademicyear,
// //     roleid,userid,isloading,setisloading
// //   } = useContext(Master);

// //   useEffect(() => {
// //     // Fetch the current academic year on component mount
// //     setisloading(true);
// //     axios({
// //       url: baseurl+'/academicyears/current',
// //       method: 'GET',
// //       headers: {
// //         authorization: `Bearer ${localStorage.getItem('token')}`,
// //       },
// //       // data: {userid:userid,roleid:roleid},
// //     })
// //       .then((response) => {
// //         if (response.data.success) {
// //           const { start, end } = response.data.data;
// //           const formattedYear = `${start+""}-${(end+"").substring(2,4)}`;
// //           setacademicyear(formattedYear);
// //           setacademicyearid(response.data.data.id);
// //           setiscurrent(response.data.data.iscurrent === 1);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error('Error fetching current academic year:', error);
// //       })
// //       .finally(() => {
// //         setisloading(false);
// //       });
// //   }, []);

// //   const handleacademicyearChange = (e) => {
// //     const value = e.target.value;
// //     const regex = /^\d{4}-\d{2}$/;

// //     if (regex.test(value)) {
// //       const [startYear, endYear] = value.split('-').map(Number);
// //       var currentendyear = (startYear+1)
// //       currentendyear = parseInt((currentendyear+"").substring(2,4)+"");

// //       if (endYear === currentendyear) {
// //         setacademicyear(value);
// //       } else {
// //         console.error('Invalid academic year format');
// //       }
// //     } else {
// //       setacademicyear(value);
// //     }
// //   };

// //   const handleacademicyearupdate = () => {
// //     const newStatus = !iscurrent;
// //     setiscurrent(newStatus);
// //     setisloading(true);

// //     // Update the academic year status in the backend
// //     axios({
// //       url: baseurl + '/updateacademicyear',
// //       method: 'POST',
// //       headers: {
// //         authorization: `Bearer ${localStorage.getItem('token')}`,
// //       },
// //       data: {
// //         id: academicid,
// //         start: academicyear.split('-')[0],
// //         end: `${academicyear.split('-')[0].slice(0, 2)}${academicyear.split('-')[1]}`,
// //         iscurrent: newStatus ? 1 : 0,
// //       },
// //     })
// //       .then((response) => {
// //         if (response.data.success) {
// //         }
// //       })
// //       .catch((error) => {
// //       })
// //       .finally(()=> {
// //         setisloading(false);
// //       });
// //   };

// //   return (
// //     <div className="academic-year-input">
// //       {roleid ==process.env.REACT_APP_ADMIN &&
// //         <>
// //           <div className="field">
// //             <label>Academic Year:</label>

// //             <input
// //               type="text"
// //               value={academicyear}
// //               onChange={handleacademicyearChange}
// //               placeholder="e.g., 2024-25"
// //               maxLength="7"
// //               onBlur={handleacademicyearupdate}
// //               onMouseOut={handleacademicyearupdate}
// //             />
// //           </div>

// //         </>
// // }

// // {
// // ((roleid ==process.env.REACT_APP_ASSESSOR ) || (roleid ==process.env.REACT_APP_COLLEGE))&&
// //         <span>Academic Year: {academicyear || '—'}</span>

// // }

// // {isloading && (
// //   <div className="loading-overlay">
// //     <div className="spinner"></div>
// //   </div>
// // )}
// // </div>
// //   );
// // };

// // export default AcademicYearInput;

// ////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useContext, useState, useEffect } from 'react';
// import Master from './Master';
// import axios from 'axios';
// import {
//   Box,
//   TextField,
//   Typography,
//   CircularProgress,
//   FormControl,
//   InputLabel,
//   InputProps,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import Button from '@mui/material/Button';

// const AcademicYearInput = () => {
//   const {
//     academicid,
//     setacademicid,
//     iscurrent,
//     setiscurrent,
//     baseurl,
//     academicyearid,
//     setacademicyearid,
//     academicyear,
//     setacademicyear,
//     roleid,
//     userid,
//     isloading,
//     setisloading,
//   } = useContext(Master);
//   const [message, setmessage] = useState(""); // message state for success or error
//     const [messagetype, setmessagetype] = useState("success"); // message type for styling

//   useEffect(() => {
//     // Fetch the current academic year on component mount
//     setisloading(true);
//     axios({
//       url: `${baseurl}/academicyear`,
//       method: 'GET',
//       headers: {
//         authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//     })
//       .then((response) => {
//         if (response.data.success) {
//           const { start, end } = response.data.data;
//           const formattedYear = `${start}-${end.toString().substring(2, 4)}`;
//           setacademicyear(formattedYear);
//           setacademicyearid(response.data.data.id);
//           setiscurrent(response.data.data.iscurrent === 1);
//           setmessage("Academicyear fetched successfully!"); // Set success message
//           setmessagetype("success");
//         }
//         else{
//           setmessage("Failed to fetch academicyear.");
//           setmessagetype("error");
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching current academic year:', error);
//         setmessage("Error during API call.");
//         setmessagetype("error");
//       })
//       .finally(() => {
//         setisloading(false);
//       });
//   }, []);

//   const handleacademicyearChange = (e) => {
//     const value = e.target.value;
//     const regex = /^\d{4}-\d{2}$/;

//     if (regex.test(value)) {
//       const [startYear, endYear] = value.split('-').map(Number);
//       const currentendyear = (startYear + 1) % 100;

//       if (endYear === currentendyear) {
//         setacademicyear(value);
//       } else {
//         console.error('Invalid academic year format');
//       }
//     } else {
//       setacademicyear(value);
//     }
//   };

//   const handleacademicyearupdate = () => {
//     const newStatus = !iscurrent;
//     setiscurrent(newStatus);
//     setisloading(true);

//     // Update the academic year status in the backend
//     axios({
//       url: `${baseurl}/academicyear`,
//       method: 'POST',
//       headers: {
//         authorization: `Bearer ${localStorage.getItem('token')}`,
//       },
//       data: {
//         id: academicyearid,
//         start: academicyear.split('-')[0],
//         end: `${academicyear.split('-')[0].slice(0, 2)}${academicyear.split('-')[1]}`,
//         iscurrent: newStatus ? 1 : 0,
//       },
//     })
//       .then((response) => {
//         if (response.data.success) {
//           console.log('Academic year updated successfully.');
//           setmessage("Academicyear updated successfully!"); // Set success message
//           setmessagetype("success");
//         }
//         else{
//           setmessage("Failed to update academicyear");
//           setmessagetype("error");
//         }
//       })
//       .catch((error) => {
//         console.error('Error updating academic year:', error);
//         setmessage("Error during API call.");
//         setmessagetype("error");
//       })
//       .finally(() => {
//         setisloading(false);
//       });
//   };

//   return (
//     <Box sx={{ p: 2 }}>
//       {roleid === process.env.REACT_APP_ADMIN && (
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth>
//             {/* <InputLabel>Academic Year</InputLabel> */}
//             <TextField
//               label="Academic Year"
//               variant="outlined"
//               value={academicyear}
//               onChange={handleacademicyearChange}
//               placeholder="e.g., 2024-25"
//               slotProps={{ maxLength: 7 }}
//               onBlur={handleacademicyearupdate}
//               onMouseOut={handleacademicyearupdate}
//               fullWidth
//             />
//           </FormControl>
//         </Box>
//       )}

//       {(roleid === process.env.REACT_APP_ASSESSOR || roleid === process.env.REACT_APP_COLLEGE) && (
//         <Typography variant="body1">
//           Academic Year: {academicyear || '—'}
//         </Typography>
//       )}

//       {isloading && (
//         <Box
//           sx={{
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100vw',
//             height: '100vh',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)',
//             zIndex: 1300,
//           }}
//         >
//           <CircularProgress />
//         </Box>
//       )}
//       {/* Snackbar for displaying success or error messages */}
//             <Snackbar
//               open={message !== ""}
//               autoHideDuration={6000}
//               onClose={() => setmessage("")}
//               message={message}
//               severity={messagetype}
//               // anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position at the bottom center
//             >
//             <Alert
//                       onClose={() => setmessage("")}
//                       severity={messagetype}
//                       sx={{ width: "100%" }}
//                     >
//                       {message}
//                     </Alert>
//                     </Snackbar>
//     </Box>
//   );
// };

// export default AcademicYearInput;
import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Radio,
  FormControlLabel,
  FormControl,
  Box,
  Typography,
  IconButton,
  Alert,
} from "@mui/material";
import axios from "axios";
import Master from "./Master";
import moment from "moment";

const AcademicYearInput = () => {
  const { baseurl } = useContext(Master);
  const [data, setData] = useState([]); // Stores academic year records
  const [editingRecord, setEditingRecord] = useState(null); // Record being edited
  const [newRecord, setNewRecord] = useState({
    academicyear: "",
    startdate: "",
    enddate: "",
    currentyear: 0,
  });

  const [error, setError] = useState(""); // Error message for validation
  const [success, setSuccess] = useState(""); // Success message
  const [isNewRecordAdded, setIsNewRecordAdded] = useState(false); // Flag for new record added
  const token = localStorage.getItem("token"); // Example: Retrieving token from localStorage

  // Fetch records from the database
  useEffect(() => {
    axios
      .get(baseurl + "/academicyears", {
        headers: {
          authorization: `Bearer ${token}`, // Include Bearer token in the header
        },
      }) // Make sure to update the endpoint for your backend
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  // Validation for academic year (format yyyy-yy)
  const validateAcademicYear = (academicyear) => {
    const regex = /^[0-9]{4}-[0-9]{2}$/; // Regular expression for yyyy-yy format
    return regex.test(academicyear);
  };

  // Check for duplicate academic year
  const isDuplicateAcademicYear = (academicyear) => {
    const [startYear, endYear] = academicyear.split("-");
    return data.some((record) => {
      const recordAcademicYear = `${record.start}-${record.end}`;
      return (
        recordAcademicYear === academicyear &&
        (!editingRecord || record.id !== editingRecord.id)
      );
    });
  };

  // Add or Update Record
  const handleSave = () => {
    const { academicyear, startdate, enddate } = newRecord;

    // Validation checks
    if (!academicyear || !validateAcademicYear(academicyear)) {
      setError("Please enter a valid academic year in the format yyyy-yy.");
      return;
    }

    if (isDuplicateAcademicYear(academicyear)) {
      setError("The academic year already exists.");
      return;
    }

    if (!startdate || !enddate) {
      setError("Start date and end date are required.");
      return;
    }

    if (new Date(startdate) > new Date(enddate)) {
      setError("End date cannot be earlier than start date.");
      return;
    }

    const [startYear, endYear] = academicyear.split("-");
    const start = parseInt(startYear);
    var end = start + 1;

    const recordToSave = {
      ...newRecord,
      start,
      end,
    };

    if (editingRecord) {
      // Update existing record
      axios
        .put(baseurl + `/academicyear/${editingRecord.id}`, recordToSave)
        .then(() => {
          setData((prevData) =>
            prevData.map((item) =>
              item.id == editingRecord.id ? recordToSave : item
            )
          );
          setEditingRecord(null); // Reset editing state
          setSuccess("Record updated successfully!");
          setNewRecord({
            academicyear: "",
            startdate: "",
            enddate: "",
            currentyear: 0,
          });
        })
        .catch((error) => {
          console.error("Error updating record:", error);
          setError("There was an error updating the record.");
        });
    } else {
      // Add new record
      axios
        .post(baseurl + "/academicyear", recordToSave)
        .then((response) => {
          setData([...data, response.data]);
          setSuccess("Record added successfully!");
          setNewRecord({
            academicyear: "",
            startdate: "",
            enddate: "",
            currentyear: 0,
          });
          setIsNewRecordAdded(true); // Show the current year radio button after adding a record
        })
        .catch((error) => {
          console.error("Error adding record:", error);
          setError("There was an error adding the record.");
        });
    }

    // Clear error if everything is good
    setError("");
  };

  // Handle radio button click for current year update
  const handleRadioChange = (id) => {
    console.log("id", id);

    axios
      .put(baseurl + `/academicyear/${id}`)
      .then(() => {
        setData((prevData) =>
          prevData.map((item) => ({
            ...item,
            currentyear: item.id === id ? 1 : 0,
          }))
        );
      })
      .catch((error) => console.error("Error updating current year:", error));
  };

  // Handle record edit
  const handleEdit = (record) => {
    setEditingRecord(record);
    setNewRecord({
      academicyear: `${record.start}-${record.end}`,
      startdate: record.startdate,
      enddate: record.enddate,
      currentyear: record.currentyear,
    });
    setError(""); // Clear error when editing
    setIsNewRecordAdded(false); // Hide current year button while editing
  };

  // Handle field change
  const handleChange = (e) => {
    setNewRecord({
      ...newRecord,
      [e.target.name]: e.target.value,
    });
  };

  const handleacademicyearChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{4}-\d{2}$/;

    if (regex.test(value)) {
      const [startYear, endYear] = value.split("-").map(Number);
      var currentendyear = startYear + 1;
      currentendyear = parseInt((currentendyear + "").substring(2, 4) + "");

      console.log(
        ">>>>>>>>>        ",
        startYear + "-" + (startYear + 1 + "".substring(2, 4))
      );
      console.log(
        "<<<<<<<<<<<<<<<<<<<     ",
        (startYear + 1 + "").substring(2, 4)
      );

      if (endYear === currentendyear) {
        // setacademicyear(value);
        setNewRecord({
          ...newRecord,
          ["start"]: startYear,
          ["end"]: startYear + 1,
          ["academicyear"]:
            startYear + "-" + (startYear + 1 + "").substring(2, 4),
        });
      } else {
        console.error("Invalid academic year format");
      }
    } else {
      // setacademicyear(value);
      const regex = /^[0-9-]*$/;
      if (regex.test(value)) {
        setNewRecord({
          ...newRecord,
          ["academicyear"]: value,
        });
      }
    }
  };

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Academic Year Management
      </Typography>

      {/* Error and Success messages */}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <Box sx={{ marginBottom: 2 }}>
        {/* Academic Year input in yyyy-yy format */}
        <TextField
          label="Academic Year"
          name="academicyear"
          value={newRecord.academicyear}
          onChange={handleacademicyearChange}
          sx={{ width: "150px", marginRight: 2 }}
        />
        <TextField
          label="Start Date"
          name="startdate"
          type="date"
          value={newRecord.startdate}
          onChange={handleChange}
          sx={{ width: "150px", marginRight: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          name="enddate"
          type="date"
          value={newRecord.enddate}
          onChange={handleChange}
          sx={{ width: "150px", marginRight: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        {/* Show Current Year radio button only after adding a new record */}
        {isNewRecordAdded && (
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Radio
                  checked={newRecord.currentyear === 1}
                  onChange={() =>
                    setNewRecord({ ...newRecord, currentyear: 1 })
                  }
                  value={1}
                />
              }
              label="Current Year"
            />
          </FormControl>
        )}
        <Button variant="contained" color="primary" onClick={handleSave}>
          {editingRecord ? "Update" : "Add"}
        </Button>
      </Box>

      {/* Displaying the Academic Year Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Academic Year</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Current Year</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record) => (
              <TableRow
                key={record.id}
                sx={{
                  border: record.currentyear === 1 ? "3px solid green" : "",
                }}
              >
                <TableCell>{`${record.start}-${
                  (record.end + "").substring(2, 4) + ""
                }`}</TableCell>
                <TableCell>
                  {moment(record.startdate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  {moment(record.enddate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  <Radio
                    checked={record.currentyear === 1}
                    // onChange={() => handleRadioChange(record.id)}
                    onClick={() => handleRadioChange(record.id)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(record)}>
                    <Typography variant="body2">Edit</Typography>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AcademicYearInput;
