import axios from "axios";
import Master from "../../Master";
import "../../Css/MenuBar/BankDetail.css";
import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

export default function BankDetail() {
  const MasterCxt = useContext(Master);

  const [bankname, setbankname] = useState("");
  const [ifsccode, setifsccode] = useState("");
  const [loading, setLoading] = useState(false);

  // Retrieve the Bearer token (adjust as needed depending on where you store the token)

  const token = localStorage.getItem("token"); // Example: Retrieving token from localStorage

  // Snackbar state

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // success or error
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch current bankcode on component mount

  useEffect(() => {
    fetchBankCode();
  }, []);

  // Fetch bank code data

  const fetchBankCode = async () => {
    setLoading(true);

    try {
      const response = await axios.get(MasterCxt.baseurl + "/bank", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setbankname(response.data?.data[0].bankname);
          setifsccode(response.data?.data[0].ifsccode);
        } else {
          setMessage("Bank code not yet set, Please insert new bank code");
        }
      } else {
        setMessage("Error fetching bank code");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSeverity("error");
      setOpenSnackbar(true);
      setMessage("Failed to fetch bank code");
    }
  };

  // Handle blur or mouse out to update bank code

  const handleSave = async () => {
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (!bankname || !ifsccode) {
      setSeverity("error");
      setOpenSnackbar(true);
      setMessage("Both bank name and IFSC code are required");

      return;
    }

    if (!ifscRegex.test(ifsccode)) {
      setSeverity("error");
      setOpenSnackbar(true);
      setMessage("Invalid IFSC code");

      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        MasterCxt.baseurl + "/bank",
        { bankname: bankname, ifsccode: ifsccode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOpenSnackbar(true);
      setSeverity("success");
      setMessage(response.data.message);
      fetchBankCode(); // Refresh the data after update
    } catch (error) {
      setSeverity("error");
      setOpenSnackbar(true);
      setMessage("Failed to update bank code");
    } finally {
      setLoading(false);
    }
  };

  // Close Snackbar

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="bankDetailMainContainer">
      {loading && (
        <CircularProgress
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      )}

      <div className="bankDetailHeader">Bank Code Management</div>
      <TextField
        value={bankname}
        label="Bank Name"
        className="bankName"
        onChange={(e) => setbankname(e.target.value)}
        style={{ marginBottom: "10px", display: "block" }}
      />
      <TextField
        value={ifsccode}
        label="IFSC Code"
        className="bankIFSCCode"
        onChange={(e) => setifsccode(e.target.value)}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={loading} // Disable button while loading
        onClick={handleSave}
        className="bankDetailBtn"
      >
        Save
      </Button>

      {/* Snackbar for success/error messages */}
      <Snackbar
        message={message}
        open={openSnackbar}
        severity={severity}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      ></Snackbar>
    </div>
  );
}
