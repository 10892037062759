import React from "react";
import constants from "../../constants";

export default function HeaderSvgIcon(Props) {
  console.log(Props);

  return (
    <div className="headerSvgIcon" onClick={Props.onClick}>
      {Props.icon === constants.HEADER.PROFILE ? (
        <svg
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 20 20"
          className="profileSvgIcon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#667085"
            stroke-width="1.44"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.6667 17.5C16.6667 16.337 16.6667 15.7555 16.5231 15.2824C16.2 14.217 15.3663 13.3834 14.3009 13.0602C13.8278 12.9167 13.2463 12.9167 12.0833 12.9167H7.91667C6.75369 12.9167 6.17221 12.9167 5.69904 13.0602C4.63371 13.3834 3.80003 14.217 3.47686 15.2824C3.33333 15.7555 3.33333 16.337 3.33333 17.5M13.75 6.25C13.75 8.32107 12.0711 10 9.99999 10C7.92893 10 6.24999 8.32107 6.24999 6.25C6.24999 4.17893 7.92893 2.5 9.99999 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z"
          />
        </svg>
      ) : Props.icon === constants.HEADER.SETTINGS ? (
        <svg
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#79747E"
            d="M9.24922 22L8.84922 18.8C8.63255 18.7167 8.42839 18.6167 8.23672 18.5C8.04505 18.3833 7.85755 18.2583 7.67422 18.125L4.69922 19.375L1.94922 14.625L4.52422 12.675C4.50755 12.5583 4.49922 12.4458 4.49922 12.3375V11.6625C4.49922 11.5542 4.50755 11.4417 4.52422 11.325L1.94922 9.375L4.69922 4.625L7.67422 5.875C7.85755 5.74167 8.04922 5.61667 8.24922 5.5C8.44922 5.38333 8.64922 5.28333 8.84922 5.2L9.24922 2H14.7492L15.1492 5.2C15.3659 5.28333 15.5701 5.38333 15.7617 5.5C15.9534 5.61667 16.1409 5.74167 16.3242 5.875L19.2992 4.625L22.0492 9.375L19.4742 11.325C19.4909 11.4417 19.4992 11.5542 19.4992 11.6625V12.3375C19.4992 12.4458 19.4826 12.5583 19.4492 12.675L22.0242 14.625L19.2742 19.375L16.3242 18.125C16.1409 18.2583 15.9492 18.3833 15.7492 18.5C15.5492 18.6167 15.3492 18.7167 15.1492 18.8L14.7492 22H9.24922ZM12.0492 15.5C13.0159 15.5 13.8409 15.1583 14.5242 14.475C15.2076 13.7917 15.5492 12.9667 15.5492 12C15.5492 11.0333 15.2076 10.2083 14.5242 9.525C13.8409 8.84167 13.0159 8.5 12.0492 8.5C11.0659 8.5 10.2367 8.84167 9.56172 9.525C8.88672 10.2083 8.54922 11.0333 8.54922 12C8.54922 12.9667 8.88672 13.7917 9.56172 14.475C10.2367 15.1583 11.0659 15.5 12.0492 15.5Z"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#667085"
            stroke-width="1.44"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13.3333 14.1663L17.5 9.99961M17.5 9.99961L13.3333 5.83294M17.5 9.99961H7.5M10 14.1663C10 14.4126 10 14.5357 9.99085 14.6424C9.89569 15.7512 9.07905 16.6636 7.98753 16.8807C7.88252 16.9015 7.76001 16.9152 7.51529 16.9424L6.66412 17.0369C5.3854 17.179 4.74601 17.25 4.23805 17.0875C3.56078 16.8708 3.00785 16.3759 2.71765 15.7267C2.5 15.2398 2.5 14.5965 2.5 13.3099V6.68935C2.5 5.40274 2.5 4.75944 2.71765 4.27254C3.00785 3.62334 3.56078 3.12844 4.23805 2.91171C4.74601 2.74916 5.38538 2.8202 6.66412 2.96229L7.51529 3.05686C7.7601 3.08406 7.8825 3.09766 7.98753 3.11854C9.07905 3.33557 9.89569 4.24797 9.99085 5.35678C10 5.46347 10 5.58662 10 5.83294"
          />
        </svg>
      )}
    </div>
  );
}
