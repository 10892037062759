import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Snackbar,Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Alert } from '@mui/lab';
import Master from './Master';

const AffiliationFeeStructure = () => {

  const {
    baseurl
 } = useContext(Master);
  const [feeStructures, setFeeStructures] = useState({ UG: [], PG: [] });
  const [loading, setLoading] = useState(false);
  const [newFeeStructure, setNewFeeStructure] = useState({ UG: null, PG: null }); // State to hold the new entry
  // const [token, setToken] = useState('YOUR_BEARER_TOKEN'); // Replace with actual logic to fetch the token

  const token = localStorage.getItem('token');  // Example: Retrieving token from localStorage

  // Snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success'); // success or error

  // Fetch fee structures on mount
  useEffect(() => {
    fetchFeeStructures('UG');
    fetchFeeStructures('PG');
  }, []);

  // Fetch fee structure data for a program
  const fetchFeeStructures = async (program) => {
    setLoading(true);
    try {
      const response = await axios.get(baseurl+`/affiliation-fee-structure?program=${program}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFeeStructures(prevState => ({
        ...prevState,
        [program]: Array.isArray(response.data.data) ? response.data.data : [],
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage('Failed to fetch fee structures');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handle change of fee structure (for both existing and new)
  const handleChange = (program, field, value, index = null) => {
    if (index !== null) {
      const updatedFeeStructures = feeStructures[program].map((fs, idx) =>
        idx === index ? { ...fs, [field]: value } : fs
      );
      setFeeStructures((prevState) => ({
        ...prevState,
        [program]: updatedFeeStructures,
      }));
    } else {
      // For new entry (empty row)
      setNewFeeStructure((prevState) => ({
        ...prevState,
        [program]: {
          ...prevState[program],
          [field]: value,
        },
      }));
    }
  };

  // Handle CRUD operations
  const handleCreate = async (program, min_students, max_students, fee) => {
    setLoading(true);
    try {
      const response = await axios.post(
        baseurl+'/affiliation-fee-structure',
        {
          program,
          min_students,
          max_students,
          fee,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(program); // Refresh list
      setNewFeeStructure((prevState) => ({ ...prevState, [program]: null })); // Reset new fee structure
    } catch (error) {
      setMessage('Failed to create fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (program, id, min_students, max_students, fee) => {
    setLoading(true);
    try {
      const response = await axios.put(
        baseurl+'/affiliation-fee-structure',
        { id, min_students, max_students, fee },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(program); // Refresh list
    } catch (error) {
      setMessage('Failed to update fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (program, id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        baseurl+'/affiliation-fee-structure',
        {
          data: { id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setSeverity('success');
      setOpenSnackbar(true);
      fetchFeeStructures(program); // Refresh list
    } catch (error) {
      setMessage('Failed to delete fee structure');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle adding new fee structure (creating)
  const handleAddNewFeeStructure = (program) => {
    // Create a new empty fee structure for the program
    setNewFeeStructure((prevState) => ({
      ...prevState,
      [program]: { min_students: '', max_students: '', fee: '' }, // Empty fields to enter data
    }));
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      {loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}

      <Grid container spacing={3}>
        {/* UG Program */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>UG Fee Structure</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.UG.length > 0 ? (
                feeStructures.UG.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('UG', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('UG', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('UG', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('UG', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('UG', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for UG program.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for UG */}
              {newFeeStructure.UG && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.UG.min_students}
                      onChange={(e) => handleChange('UG', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.UG.max_students}
                      onChange={(e) => handleChange('UG', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.UG.fee}
                      onChange={(e) => handleChange('UG', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('UG', newFeeStructure.UG.min_students, newFeeStructure.UG.max_students, newFeeStructure.UG.fee)}>
                      Create New UG Fee Structure
                    </Button> */}
                     <Box sx={{ marginBottom: 2 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreate('UG', newFeeStructure.UG.min_students, newFeeStructure.UG.max_students, newFeeStructure.UG.fee)}
                                sx={{ whiteSpace: 'nowrap' }}>
                               Create New 
                              </Button>
                            </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* <Button onClick={() => handleAddNewFeeStructure('UG')}>Add New UG Fee Structure</Button> */}
          <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddNewFeeStructure('UG')}
                    >
                     Add New 
                    </Button>
                  </Box>
        </Grid>

        {/* PG Program */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>PG Fee Structure</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Min Students</TableCell>
                <TableCell>Max Students</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeStructures.PG.length > 0 ? (
                feeStructures.PG.map((fs, index) => (
                  <TableRow key={fs.id}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.min_students}
                        onChange={(e) => handleChange('PG', 'min_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.max_students}
                        onChange={(e) => handleChange('PG', 'max_students', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={fs.fee}
                        onChange={(e) => handleChange('PG', 'fee', e.target.value, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleUpdate('PG', fs.id, fs.min_students, fs.max_students, fs.fee)}>Update</Button>
                      <Button onClick={() => handleDelete('PG', fs.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No fee structures found for PG program.</TableCell>
                </TableRow>
              )}

              {/* Display new fee structure input fields for PG */}
              {newFeeStructure.PG && (
                <TableRow>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.PG.min_students}
                      onChange={(e) => handleChange('PG', 'min_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.PG.max_students}
                      onChange={(e) => handleChange('PG', 'max_students', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={newFeeStructure.PG.fee}
                      onChange={(e) => handleChange('PG', 'fee', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button onClick={() => handleCreate('PG', newFeeStructure.PG.min_students, newFeeStructure.PG.max_students, newFeeStructure.PG.fee)}>
                      Create New PG Fee Structure
                    </Button> */}

                     <Box sx={{ marginBottom: 2 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreate('PG', newFeeStructure.PG.min_students, newFeeStructure.PG.max_students, newFeeStructure.PG.fee)}
                                sx={{ whiteSpace: 'nowrap' }}>
                               Create New 
                              </Button>
                            </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* <Button onClick={() => handleAddNewFeeStructure('PG')}>Add New PG Fee Structure</Button> */}
          <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddNewFeeStructure('PG')}
                    >
                     Add New 
                    </Button>
                  </Box>
        </Grid>
      </Grid>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AffiliationFeeStructure;
