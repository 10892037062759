// import React, { useState, useEffect,useContext } from 'react';  
// import './Paymentaffiliation.css';
// import axios from 'axios';
// import Master from "./Master";

// const Paymentaffiliation = () => {

//   const {
//     baseurl,userid, academicyearid,selectedrequestobject, token,roleid, setselectedrequestobject
//  } = useContext(Master);




//   const [isconfirmed, setisconfirmed] = useState(false);
//   const [paymentdata, setpaymentdata] = useState([]);
//   const [reload, setreload] = useState(false);

//   const handlecheckboxchange = (event) => {
//     setisconfirmed(event.target.checked);
//   };


// const handlerequestpayment=()=>{


//   axios({
//     url: baseurl+"/makepaymentrequest",
//     method: "POST",
//     headers: {
//         authorization: `Bearer ${localStorage.getItem("token")}`  ,
//     },

//       data: {
//         affiliationrequestid:selectedrequestobject.requestid,
//         paymenttypeid:2,
//         status:0,
//         amount:15000,
//         bankrequestid:111111111
// }



// }
// )
//     .then((response) => {         
//         if(response.data.success){

//           setreload(!reload);

//         }

//     })
//     .catch((error) => {

//     });

// }



// const handlermakepayment=()=>{


//   axios({
//     url: baseurl+"/makepayment",
//     method: "POST",
//     headers: {
//         authorization: `Bearer ${localStorage.getItem("token")}`  ,
//     },

//       data: {
//         affiliationrequestid:selectedrequestobject.requestid,
//         paymenttypeid:2,
//         status:1,
//         utr:"9876789065",
//         amount:15000,
//         // bankrequestid:
// }

// }
// )
//     .then((response) => {         
//         if(response.data.success){
//           setreload(!reload);
//         }

//     })
//     .catch((error) => {

//     });

// }







// useEffect(() => {


//     axios({
//       url: baseurl+"/getpaymentdetails",
//       method: "POST",
//       headers: {
//            authorization: `Bearer ${localStorage.getItem("token")}`  ,
//       },
//       data: {affiliationrequestid:selectedrequestobject.requestid,
//               userid:selectedrequestobject.userid,
//               paymenttypeid:2
//       },

//   }).then((response) => {


//     if(response.data.success){

//       if(response.data.data.length>0){

//         setpaymentdata(response.data.data[0])

//         if(response.data.data[0].status==1){


//         setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:true}))
//         }else{
//           setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:false}))

//         }
//     }else{
//       setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:false}))
//       setpaymentdata({})
//     }

//   }  
// })
// .catch((error) => {


// });


// }, [,reload]);




//   return (

//     <>
//     <div className='paymentwrapper'>
//     {( roleid==process.env.REACT_APP_ADMIN || roleid==process.env.REACT_APP_COLLEGE )&& <>
//     <div className="payment-container">
//       <h2>Affiliation Payment</h2>

//       { roleid == process.env.REACT_APP_ADMIN && ( paymentdata.status==null ) && <>
//       <div className="checkbox-container">
//         <input
//           type="checkbox"
//           id="accessor-fee"
//           checked={isconfirmed}
//           onChange={handlecheckboxchange}
//         />
//         <label htmlFor="accessor-fee">Confirm Affiliation Fee of Rs.200000</label>
//       </div>

//       <button
//         className="request-button"
//         disabled={!isconfirmed}
//         onClick={() => handlerequestpayment()}
//       >
//         Request Affiliation Payment
//       </button>


//       {/* <button
//         className="request-button"
//         disabled={!isconfirmed}
//         onClick={() => 
//       >
//         Request Payment
//       </button> */}
// </>
//     }


// { paymentdata.status!=null && <>
// <>

// <h3>Status: {paymentdata.status==1?"Payment Successfull UTR:+ "+paymentdata.utr:"Payment Pending"}</h3>

// </>


// </>}

// { (roleid == process.env.REACT_APP_COLLEGE && paymentdata.status==null) && <>

// Payment notification will be sent to you shortly.


// </>
// }

//     {roleid == process.env.REACT_APP_COLLEGE && (paymentdata.status==0 ) &&<>

//       <button
//         className="request-button"
//         // disabled={!isconfirmed}
//         onClick={()=>handlermakepayment()}
//       >
//         Make Payment Rs 200000
//       </button>

//     </>}
//     </div>
//     </>}
//     </div>
//     </>
//   );
// };

// export default Paymentaffiliation;


import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, CircularProgress, Typography, Box, Button
} from '@mui/material';
import Master from './Master';
import { set } from 'lodash';
const Paymentaffiliation = () => {
  const {
    baseurl, affiliationrequestid, setaffiliationpaymentcomplete, roleid, selectedrequestobject
  } = useContext(Master);
  const [courseDetails, setCourseDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentcomplete, setpaymentcomplete] = useState(false);
  // Retrieve the Bearer token (adjust as needed depending on where you store the token)
  const token = localStorage.getItem('token');  // Example: Retrieving token from localStorage


  // Fetch course details on component mount or when requestid or token changes
  useEffect(() => {

    if (!token) return;

    var requestid=null
    console.log("paymentDetails-------", affiliationrequestid);
    if (Object.keys(selectedrequestobject).length ==0 && affiliationrequestid != null) {
      
      // setaffiliationrequestid(selectedrequestobject.requestid);
      requestid=affiliationrequestid
      
    }
    else if(Object.keys(selectedrequestobject).length >0 ){
      requestid=selectedrequestobject.requestid
    } else{
      return;
    }

    setLoading(true);
    setError(null);
    axios
      .get(baseurl + `/payments/affiliation/${requestid}`, {
        headers: {
          authorization: `Bearer ${token}` // Pass JWT token in the header
        }
      })
      .then((response) => {
        setCourseDetails(response.data.data); // Store course details in state
        var paymentcomplete = true;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status != 1) {
            paymentcomplete = false;
          }
        }
        setaffiliationpaymentcomplete(paymentcomplete);
        setpaymentcomplete(paymentcomplete);


        setLoading(false);
      })
      .catch((error) => {
        setError(error.response ? error.response.data.message : 'Error fetching course details');
        setLoading(false);
      });
  }, []); // Trigger re-fetch if requestid or token changes

  // if (loading) {
  //   return <CircularProgress sx={{ display: 'block', margin: 'auto' }} />;
  // }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // if (!courseDetails) {
  //   return <Typography>No data found for the provided requestid</Typography>;
  // }


  return (
    <>
    
      {(roleid == process.env.REACT_APP_ADMIN || roleid == process.env.REACT_APP_COLLEGE) &&

        < div className='selectaccessorwrapper'>
          <TableContainer component={Box}>
            <div className="component-header"> Affiliation fee </div>
            <Table sx={{ maxWidth: '100%' }} aria-label="Requested Course Details">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Course Code</TableCell> */}
                  <TableCell>Course</TableCell> 
                  <TableCell>Total Seats</TableCell>
                  <TableCell>Fee</TableCell>
                  <TableCell>GST</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Account Details</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>UTR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseDetails?.map((record) => (
                  <TableRow key={record.requestid}>
                    {/* <TableCell>{record.coursecode}</TableCell> */}
                    <TableCell>{record.course}</TableCell>
                    <TableCell>{record.seats}</TableCell>
                    <TableCell>{record.fee}</TableCell>
                    <TableCell>{record.gst}</TableCell>
                    <TableCell>{record.total}</TableCell>
                    <TableCell>
                      {"A/c No: " + `${record.payment_account}`}<br />
                      {"Bank Name: " + record.bankname}<br />
                      {"IFSC Code: " + record.ifsccode}
                    </TableCell>
                    <TableCell>{record.status == 1 ?
                      <Box sx={{ marginBottom: 2 }}>
                        <Button
                          variant="contained"
                          color="success"
                        >
                          Paid
                        </Button>
                      </Box>


                      :


                      <Box sx={{ marginBottom: 2 }}>
                        <Button
                          variant="contained"
                          color="warning"
                        >
                          Pending
                        </Button>
                      </Box>

                    }</TableCell>
                    <TableCell>{record.status == 1?record.utr:"-"}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>


          </TableContainer>
          {loading && (
            <div style={{
              position: 'absolute', top: '50%', left: '50%',
              transform: 'translate(-50%, -50%)', zIndex: 1
            }}>
              <CircularProgress />
            </div>
          )}
        </ div>
      }
    </>
  );
};

export default Paymentaffiliation;
