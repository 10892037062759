import React from "react";
import constants from "../../constants";

export default function ProfileSvgIcons(Props) {
  return Props.icon === constants.PROFILE.EDIT ? (
    <svg
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      className="editSvgIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.16699 3.8332H3.33366C2.89163 3.8332 2.46771 4.00879 2.15515 4.32135C1.84259 4.63391 1.66699 5.05784 1.66699 5.49986V17.1665C1.66699 17.6086 1.84259 18.0325 2.15515 18.345C2.46771 18.6576 2.89163 18.8332 3.33366 18.8332H15.0003C15.4424 18.8332 15.8663 18.6576 16.1788 18.345C16.4914 18.0325 16.667 17.6086 16.667 17.1665V11.3332M15.417 2.5832C15.7485 2.25168 16.1982 2.06543 16.667 2.06543C17.1358 2.06543 17.5855 2.25168 17.917 2.5832C18.2485 2.91472 18.4348 3.36436 18.4348 3.8332C18.4348 4.30204 18.2485 4.75168 17.917 5.0832L10.0003 12.9999L6.66699 13.8332L7.50033 10.4999L15.417 2.5832Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.PHONE ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M5.58685 6.40223C6.05085 7.36865 6.68337 8.27441 7.48443 9.07546C8.28548 9.87651 9.19124 10.509 10.1577 10.973C10.2408 11.0129 10.2823 11.0329 10.3349 11.0482C10.5218 11.1027 10.7513 11.0636 10.9096 10.9502C10.9542 10.9183 10.9923 10.8802 11.0685 10.804C11.3016 10.571 11.4181 10.4544 11.5353 10.3782C11.9772 10.0909 12.5469 10.0909 12.9889 10.3782C13.106 10.4544 13.2226 10.571 13.4556 10.804L13.5856 10.9339C13.9398 11.2882 14.117 11.4654 14.2132 11.6556C14.4046 12.034 14.4046 12.4809 14.2132 12.8592C14.117 13.0495 13.9399 13.2266 13.5856 13.5809L13.4805 13.686C13.1274 14.0391 12.9508 14.2156 12.7108 14.3505C12.4445 14.5001 12.0308 14.6077 11.7253 14.6068C11.45 14.6059 11.2619 14.5525 10.8856 14.4457C8.86333 13.8718 6.95509 12.7888 5.36311 11.1968C3.77112 9.60479 2.68814 7.69655 2.11416 5.67429C2.00735 5.29799 1.95395 5.10984 1.95313 4.83455C1.95222 4.52906 2.0598 4.1154 2.20941 3.84907C2.34424 3.60904 2.52078 3.4325 2.87386 3.07942L2.97895 2.97433C3.33325 2.62004 3.5104 2.44289 3.70065 2.34666C4.07903 2.15528 4.52587 2.15528 4.90424 2.34666C5.0945 2.44289 5.27164 2.62004 5.62594 2.97433L5.75585 3.10424C5.98892 3.33732 6.10546 3.45385 6.18165 3.57104C6.46898 4.01296 6.46898 4.58268 6.18165 5.02461C6.10546 5.1418 5.98892 5.25833 5.75585 5.4914C5.67964 5.56761 5.64154 5.60571 5.60965 5.65026C5.4963 5.80854 5.45717 6.03805 5.51165 6.22495C5.52698 6.27754 5.54694 6.31911 5.58685 6.40223Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.WEBSITE ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.47203 12.7428L7.52922 13.6856C6.22748 14.9873 4.11693 14.9873 2.81518 13.6856C1.51343 12.3838 1.51343 10.2733 2.81518 8.97154L3.75799 8.02874M12.2433 8.97154L13.1861 8.02874C14.4878 6.72699 14.4878 4.61644 13.1861 3.31469C11.8843 2.01294 9.77378 2.01294 8.47203 3.31469L7.52922 4.2575M5.66729 10.8335L10.334 6.16679"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.EMAIL ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M1.33301 5.1665L6.77629 8.9768C7.21707 9.28535 7.43746 9.43962 7.67718 9.49938C7.88894 9.55216 8.11041 9.55216 8.32217 9.49938C8.56189 9.43962 8.78228 9.28535 9.22306 8.9768L14.6663 5.1665M4.53301 13.8332H11.4663C12.5864 13.8332 13.1465 13.8332 13.5743 13.6152C13.9506 13.4234 14.2566 13.1175 14.4484 12.7412C14.6663 12.3133 14.6663 11.7533 14.6663 10.6332V6.3665C14.6663 5.2464 14.6663 4.68635 14.4484 4.25852C14.2566 3.8822 13.9506 3.57624 13.5743 3.38449C13.1465 3.1665 12.5864 3.1665 11.4663 3.1665H4.53301C3.4129 3.1665 2.85285 3.1665 2.42503 3.38449C2.0487 3.57624 1.74274 3.8822 1.55099 4.25852C1.33301 4.68635 1.33301 5.2464 1.33301 6.3665V10.6332C1.33301 11.7533 1.33301 12.3133 1.55099 12.7412C1.74274 13.1175 2.0487 13.4234 2.42503 13.6152C2.85285 13.8332 3.4129 13.8332 4.53301 13.8332Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.MANAGEMENT ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14.6663 14.5V13.1667C14.6663 11.9241 13.8165 10.88 12.6663 10.584M10.333 2.69384C11.3103 3.08943 11.9997 4.04754 11.9997 5.16667C11.9997 6.28579 11.3103 7.2439 10.333 7.63949M11.333 14.5C11.333 13.2575 11.333 12.6362 11.13 12.1462C10.8594 11.4928 10.3402 10.9736 9.68683 10.703C9.19677 10.5 8.57552 10.5 7.33301 10.5H5.33301C4.0905 10.5 3.46924 10.5 2.97919 10.703C2.32578 10.9736 1.80665 11.4928 1.536 12.1462C1.33301 12.6362 1.33301 13.2575 1.33301 14.5M8.99967 5.16667C8.99967 6.63943 7.80577 7.83333 6.33301 7.83333C4.86025 7.83333 3.66634 6.63943 3.66634 5.16667C3.66634 3.69391 4.86025 2.5 6.33301 2.5C7.80577 2.5 8.99967 3.69391 8.99967 5.16667Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.REGISTRATION ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.33366 7.8335H5.33366M6.66699 10.5002H5.33366M10.667 5.16683H5.33366M13.3337 5.0335V11.9668C13.3337 13.0869 13.3337 13.647 13.1157 14.0748C12.9239 14.4511 12.618 14.7571 12.2416 14.9488C11.8138 15.1668 11.2538 15.1668 10.1337 15.1668H5.86699C4.74689 15.1668 4.18683 15.1668 3.75901 14.9488C3.38269 14.7571 3.07673 14.4511 2.88498 14.0748C2.66699 13.647 2.66699 13.0869 2.66699 11.9668V5.0335C2.66699 3.91339 2.66699 3.35334 2.88498 2.92552C3.07673 2.54919 3.38269 2.24323 3.75901 2.05148C4.18683 1.8335 4.74689 1.8335 5.86699 1.8335H10.1337C11.2538 1.8335 11.8138 1.8335 12.2416 2.05148C12.618 2.24323 12.9239 2.54919 13.1157 2.92552C13.3337 3.35334 13.3337 3.91339 13.3337 5.0335Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.COLLEGECODE ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#667085"
        d="M10 4H4C3.73478 4 3.48043 4.10536 3.29289 4.29289C3.10536 4.48043 3 4.73478 3 5V14.5C3 14.5919 3.02531 14.682 3.07315 14.7604C3.12099 14.8388 3.18952 14.9026 3.27121 14.9446C3.35289 14.9866 3.44459 15.0054 3.53621 14.9987C3.62784 14.992 3.71587 14.9603 3.79063 14.9069L7 12.6144L10.21 14.9069C10.2848 14.9601 10.3727 14.9917 10.4642 14.9983C10.5558 15.0048 10.6474 14.9861 10.7289 14.9441C10.8105 14.9021 10.879 14.8384 10.9268 14.7601C10.9746 14.6817 10.9999 14.5918 11 14.5V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4ZM10 13.5288L7.29 11.5913C7.2052 11.5307 7.10359 11.4981 6.99937 11.4981C6.89516 11.4981 6.79355 11.5307 6.70875 11.5913L4 13.5281V5H10V13.5288ZM13 3V12.5C13 12.6326 12.9473 12.7598 12.8536 12.8536C12.7598 12.9473 12.6326 13 12.5 13C12.3674 13 12.2402 12.9473 12.1464 12.8536C12.0527 12.7598 12 12.6326 12 12.5V3H5.5C5.36739 3 5.24021 2.94732 5.14645 2.85355C5.05268 2.75979 5 2.63261 5 2.5C5 2.36739 5.05268 2.24021 5.14645 2.14645C5.24021 2.05268 5.36739 2 5.5 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3Z"
      />
    </svg>
  ) : Props.icon === constants.PROFILE.LOCATION ? (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.00033 9.16683C9.10489 9.16683 10.0003 8.2714 10.0003 7.16683C10.0003 6.06226 9.10489 5.16683 8.00033 5.16683C6.89576 5.16683 6.00033 6.06226 6.00033 7.16683C6.00033 8.2714 6.89576 9.16683 8.00033 9.16683Z"
      />
      <path
        stroke="#667085"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.00033 15.1668C10.667 12.5002 13.3337 10.1123 13.3337 7.16683C13.3337 4.22131 10.9458 1.8335 8.00033 1.8335C5.05481 1.8335 2.66699 4.22131 2.66699 7.16683C2.66699 10.1123 5.33366 12.5002 8.00033 15.1668Z"
      />
    </svg>
  ) : (
    <></>
  );
}
