import axios from "axios";
import Master from "../../Master";
import "../../Css/MenuBar/CollegeCode.css";
import DataTable from "react-data-table-component";
import React, { useContext, useEffect, useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  Snackbar,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export default function CollegeCode() {
  const MasterCxt = useContext(Master);

  const [colleges, setColleges] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [currentCollege, setCurrentCollege] = useState({
    id: "",
    collegename: "",
    collegecode: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchColleges();
  }, []);

  const fetchColleges = async () => {
    try {
      axios({
        method: "GET",
        url: MasterCxt.baseurl + `/colleges`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          if (response.data.success) {
            setColleges(response.data.data);
          } else {
            console.error("Error fetching colleges:");
          }
        })

        .catch((error) => {
          console.error("Error during API call:", error);
        });
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      MasterCxt.setisloading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentCollege({ id: "", collegename: "", collegecode: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCurrentCollege((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (currentCollege.id) {
        // Update

        await axios.put(
          `${MasterCxt.baseurl}/${currentCollege.id}`,
          currentCollege
        );

        setSnackbar({
          open: true,
          severity: "success",
          message: "College updated successfully",
        });
      } else {
        // Create

        await axios.post(MasterCxt.baseurl, currentCollege);
        setSnackbar({
          open: true,
          severity: "success",
          message: "College added successfully",
        });
      }

      fetchColleges();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving college:", error);

      setSnackbar({
        open: true,
        severity: "error",
        message: "Failed to save college",
      });
    }
  };

  const columns = [
    {
      name: "ID",
      width: "80px",
      cell: (row) => row.id,
      className: "collegeId",
    },
    {
      name: "College Name",
      className: "collegeName",
      cell: (row) => row.collegename,
    },
    {
      width: "240px",
      name: "College Code",
      className: "collegeCode",
      cell: (row) => row.collegecode || "-",
    },
  ];

  return (
    <div className="collegeCodeMainContainer">
      <div className="collegeCodeHeader">College Management</div>
      <DataTable
        data={colleges}
        columns={columns}
        className="collegeCodeTable"
        noDataComponent="No data available."
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {currentCollege.id ? "Edit College" : "Add College"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            name="collegename"
            label="College Name"
            onChange={handleChange}
            value={currentCollege.collegename}
          />
          <TextField
            fullWidth
            margin="dense"
            name="collegecode"
            label="College Code"
            onChange={handleChange}
            value={currentCollege.collegecode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
