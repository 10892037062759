import Master from "../../Master";
import "../../Css/MenuBar/MenuBar.css";
import constants from "../../constants";
import React, { useContext } from "react";
import MenuBarSvgIcon from "../Svg/MenuBarSvgIcon";
import { Outlet, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

export default function MenuBar() {
  const MasterCxt = useContext(Master);

  const { pathname } = useLocation();

  const handleBack = () => {
    MasterCxt.navigate("/requests");
  };

  return (
    <div className="menuBarMainContainer">
      <Sidebar className="menuBarMainSideBar">
        <div className="menuBarMainInnerDiv">
          <MenuBarSvgIcon icon={constants.MENUBAR.BACK} onClick={handleBack} />
          Settings
        </div>
        <Menu transitionDuration={1000} className="menuBarMainMenu">
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("academicyear")}
            onClick={() => MasterCxt.navigate("/settings/academicyear")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.ACADEMIC} />}
          >
            Academic Year
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("collegecode")}
            onClick={() => MasterCxt.navigate("/settings/collegecode")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.CLGCODE} />}
          >
            College Code
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("gst")}
            onClick={() => MasterCxt.navigate("/settings/gst")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.GST} />}
          >
            GST
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("coursecode")}
            onClick={() => MasterCxt.navigate("/settings/coursecode")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.COURSES} />}
          >
            Courses
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("universitybankcode")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.COURSES} />}
            onClick={() => MasterCxt.navigate("/settings/universitybankcode")}
          >
            University Bank Code
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("consentfee")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.FEE} />}
            onClick={() => MasterCxt.navigate("/settings/consentfee")}
          >
            Consent Fee
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("continuationfee")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.FEE} />}
            onClick={() => MasterCxt.navigate("/settings/continuationfee")}
          >
            Continuation Fee
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("assessorfee")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.FEE} />}
            onClick={() => MasterCxt.navigate("/settings/assessorfee")}
          >
            Accessor Fee
          </MenuItem>
          <MenuItem
            className="menuBarMainMenuList"
            active={pathname.includes("bankdetails")}
            prefix={<MenuBarSvgIcon icon={constants.MENUBAR.BANK} />}
            onClick={() => MasterCxt.navigate("/settings/bankdetails")}
          >
            Bank Details
          </MenuItem>
        </Menu>
      </Sidebar>

      <main className="menuBarBody">
        <Outlet />
      </main>
    </div>
  );
}
