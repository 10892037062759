import axios from "axios";
import Master from "../../Master";
import "../../Css/RequestDetailPage/CollegeTableUG.css";
import "../../Css/RequestDetailPage/CollegeTablePGMDMS.css";
import React, { useContext, useEffect, useState } from "react";
import { UploadSvgIcon } from "../Svg/UploadSvgIcon";
// import Button from "../Button";
// import Button from "../Button";

import {
  Box,
  Alert,
  Checkbox,
  Snackbar,
  TextField,
  CircularProgress,
  Button
} from "@mui/material";

export default function CollegeTablePGMDMS() {
  const MasterCxt = useContext(Master);

  const isDisabled =
    MasterCxt.affiliationrequestid != null ||
    Object.keys(MasterCxt.selectedrequestobject).length > 0;

  const [message, setMessage] = useState(""); // Message state for success or error
  const [messageType, setMessageType] = useState("success"); // Message type for styling

  useEffect(() => {
    if (Object.keys(MasterCxt.selectedrequestobject).length === 0) {
      MasterCxt.setaffiliationrequestsubmissioncompletion((prevData) => ({
        ...prevData,
        pgmdmscoursesubmissionscomplete: null,
      }));

      axios({
        method: "GET",
        url: MasterCxt.baseurl + "/courses/pg/mdms",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          MasterCxt.setisloading(false); // Hide loading spinner

          if (response.data.success) {

            response.data.data.forEach(function (element) {
              if( element.seats==null){
                element.seats=0;
                element.change = 0;
              }


            });

            response.data.data.forEach(function (element) {
              if( element.seats==null){
                element.seats=0;
                element.change = 0;
              }


            });
            const result = response.data.data.filter(
              (obj) => obj.category === "MD/MS"
            );

            MasterCxt.setpgmdmstabledata(result);
            setMessage("Course data fetched successfully!");
            setMessageType("success");
          } else {
            setMessage("Failed to fetch Course data.");
            setMessageType("error");
          }
        })
        .catch((error) => {
          MasterCxt.setisloading(false); // Hide loading spinner
          setMessage("Error during API call.");
          setMessageType("error");
        });
    }

    return () => {
      MasterCxt.setpgmdmsmodifiedrows([]);
      MasterCxt.setpgmdmstabledata([]);
    };
  }, []);

  const handleCheckboxChange = (index, checked) => {
    const updatedData = [...MasterCxt.pgmdmstabledata];

    updatedData[index].editable = checked;
    MasterCxt.setpgmdmstabledata(updatedData);
  };

  const handleInputChange = (index, value) => {
    if(isNaN(value)||value==""){
  
      var temp=[...MasterCxt.pgmdmstabledata];
    
      temp[index].noofstudents="";
    
      MasterCxt.setpgmdmstabledata(temp);
      
      return
    
    }
    // const updatedData = [...pgmdmstabledata];
    const collegeIntake = parseInt(value, 10);
    const updatedData = [...MasterCxt.pgmdmstabledata];
    const intake = parseInt(updatedData[index].intake, 10);

    if (collegeIntake <= intake || isNaN(collegeIntake)) {
      updatedData[index].noofstudents = parseInt(value);
      updatedData[index].courseid = updatedData[index].id;

      const modifiedRow = updatedData[index];

      MasterCxt.setpgmdmsmodifiedrows((prev) => {
        const exists = prev.find((row) => row.id === modifiedRow.id);

        if (exists) {
          return prev.map((row) =>
            row.id === modifiedRow.id ? { ...modifiedRow } : row
          );
        }

        return [...prev, { ...modifiedRow }];
      });

      MasterCxt.setpgmdmstabledata(updatedData);
      MasterCxt.updaterequestcourses();
    } else {
      alert(`College intake cannot exceed ${intake}`);
    }
  };

  useEffect(() => {
    MasterCxt.updateshowformsubmissionbutton();
  }, [MasterCxt.setaffiliationrequestsubmissioncompletion]);

  useEffect(() => {
    if (Object.keys(MasterCxt.selectedrequestobject).length > 0) {
      axios({
        method: "GET",
        url: MasterCxt.baseurl + "/courses/requests/" + MasterCxt.selectedrequestobject.requestid,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          MasterCxt.setisloading(false); // Hide loading spinner

          if (response.data.success) {
            MasterCxt.setaffiliationrequestid(response.data.data.affiliationrequestid);
            setMessage(response.data.message);
            setMessageType("success");

            const result = response.data.data.filter(
              (obj) => obj.category === "MD/MS"
            );

            if (result.length > 0) {
              MasterCxt.updatepgsuboptions("MD_MS", true);
            }

            MasterCxt.setpgmdmstabledata(result);
          } else {
            setMessage(response.data.message);
            setMessageType("error");
          }
        })
        .catch((error) => {
          MasterCxt.setisloading(false); // Hide loading spinner
          setMessage(error.message);
          setMessageType("error");
          alert("Unexpected error occurred. Please try again later.");
        });
    }

    return () => {};
  }, []);

  function handleChangeSeats (index,value, type){
    
    

    // if(isNaN(value)||value==""){
  
    //   var temp=[...MasterCxt.ugtabledata];
    
    //   temp[index].change="";
    
    //   MasterCxt.setugtabledata(temp);
      
    //   return
    
    // }
    
    const updatedData = [...MasterCxt.pgmdmstabledata];

  
    
    if(type=="decrease"){

      if((updatedData[index].seats +(value-1))<0){

        alert(`College intake cannot be lessthan 0`)
        return
      }


      updatedData[index].change=updatedData[index].change-1;
      updatedData[index].totalseats=(updatedData[index].seats +updatedData[index].change);
    }
    
    if(type=="increase"){
      
      if((updatedData[index].seats +(value+1))>updatedData[index].intake){

        alert(`College intake cannot exceed ${updatedData[index].intake}`)
        return
      }

      updatedData[index].change=updatedData[index].change+1;
      updatedData[index].totalseats=(updatedData[index].seats +updatedData[index].change);
    }

    const collegeIntake = updatedData[index].seats +updatedData[index].change;
    const intake = parseInt(updatedData[index].intake, 10);

    if (collegeIntake <= intake || isNaN(collegeIntake)) {
      // updatedData[index].seats = parseInt(value);
      updatedData[index].courseid = updatedData[index].id;

      const modifiedRow = updatedData[index];

      MasterCxt.setpgmdmsmodifiedrows((prev) => {
        const exists = prev.find((row) => row.id === modifiedRow.id);

        if (exists) {
          return prev.map((row) =>
            row.id === modifiedRow.id ? { ...modifiedRow } : row
          );
        }

        return [...prev, { ...modifiedRow }];
      });

      MasterCxt.setpgmdmstabledata(updatedData);
      MasterCxt.updaterequestcourses();
    } else {
      alert(`College intake cannot exceed ${intake}`);
    }

  }

  // const handlefilechange = async (e, fileid, index) => {
  //   const updateddata = [...MasterCxt.pgmdmstabledata];

  //   updateddata[index].comment = "";
  //   updateddata[index].fileselected = true;
  //   updateddata[index].approvalstatus = null;
  //   updateddata[index].file = e.target.files[0];
  //   updateddata[index].filename = e.target.files[0].name;
  //   updateddata[index].filesize = e.target.files[0].size;
  //   updateddata[index].approvalstatus = "Action Pending";

  //   updateddata[index].fileuploadprogressstatus =
  //     "File Selected but not uploaded";

  //   MasterCxt.setpgmdmstabledata(updateddata);
    
  // };

  function handleChangeSeats (index,value, type){
    
    

    // if(isNaN(value)||value==""){
  
    //   var temp=[...MasterCxt.ugtabledata];
    
    //   temp[index].change="";
    
    //   MasterCxt.setugtabledata(temp);
      
    //   return
    
    // }
    
    const updatedData = [...MasterCxt.pgmdmstabledata];

  
    
    if(type=="decrease"){

      if((updatedData[index].seats +(value-1))<0){

        alert(`College intake cannot be lessthan 0`)
        return
      }


      updatedData[index].change=updatedData[index].change-1;
      updatedData[index].totalseats=(updatedData[index].seats +updatedData[index].change);
    }
    
    if(type=="increase"){
      
      if((updatedData[index].seats +(value+1))>updatedData[index].intake){

        alert(`College intake cannot exceed ${updatedData[index].intake}`)
        return
      }

      updatedData[index].change=updatedData[index].change+1;
      updatedData[index].totalseats=(updatedData[index].seats +updatedData[index].change);
    }

    const collegeIntake = updatedData[index].seats +updatedData[index].change;
    const intake = parseInt(updatedData[index].intake, 10);

    if (collegeIntake <= intake || isNaN(collegeIntake)) {
      // updatedData[index].seats = parseInt(value);
      updatedData[index].courseid = updatedData[index].id;

      const modifiedRow = updatedData[index];

      MasterCxt.setpgmdmsmodifiedrows((prev) => {
        const exists = prev.find((row) => row.id === modifiedRow.id);

        if (exists) {
          return prev.map((row) =>
            row.id === modifiedRow.id ? { ...modifiedRow } : row
          );
        }

        return [...prev, { ...modifiedRow }];
      });

      MasterCxt.setpgmdmstabledata(updatedData);
      MasterCxt.updaterequestcourses();
    } else {
      alert(`College intake cannot exceed ${intake}`);
    }

  }

  const handlefilechange = async (e, fileid, index) => {
    const updateddata = [...MasterCxt.pgmdmstabledata];

    updateddata[index].comment = "";
    updateddata[index].fileselected = true;
    updateddata[index].approvalstatus = null;
    updateddata[index].file = e.target.files[0];
    updateddata[index].filename = e.target.files[0].name;
    updateddata[index].filesize = e.target.files[0].size;
    updateddata[index].approvalstatus = "Action Pending";

    updateddata[index].fileuploadprogressstatus =
      "File Selected but not uploaded";

    MasterCxt.setpgmdmstabledata(updateddata);
    
  };

  return (
    <div className="collegeTablePGMDMSMainContainer">
      {MasterCxt.roleid === parseInt(process.env.REACT_APP_ADMIN) && (
        <table className="requestTableContainer">
          <th className="requestTableHeader">
            <tr className="requestTableHeaderRow">
              {Object.keys(MasterCxt.selectedrequestobject).length === 0 && (
                <td className="requestTableHeaderSelect">Select</td>
              )}
              <td className="requestTableHeaderCourse">PG MD/MS Courses</td>
              <td className="requestTableHeaderSeat">No of Seats</td>
            </tr>
          </th>
          <tbody className="requestTableBody">
            {MasterCxt.pgmdmstabledata.map((row, index) => (
              <tr key={row.id} className="requestTableBodyRowForAdmin">
                {Object.keys(MasterCxt.selectedrequestobject).length === 0 && (
                  <td className="requestTableBodySelect">
                    <Checkbox
                      checked={row.editable}
                      onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                      }
                    />
                  </td>
                )}
                <td
                  className={`requestTableBodyCourse ${
                    row.editable ? "active" : ""
                  }`}
                >
                  {row.course}
                </td>
                <td>
                  <input
                    fullWidth
                    type="number"
                    disabled={!row.editable}
                    value={row.seats}
                    className="requestTableBodySeat"
                    placeholder={`Max ${row.intake}`}
                    // onChange={(e) => handleInputChange(index, e.target.value)}
                    // onChange={(e) => handleInputChange(index, e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the default border
                          color: "white",
                        },
                      },
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {MasterCxt.roleid === parseInt(process.env.REACT_APP_COLLEGE) && (
        <table className="requestTableContainer">
          <th className="requestTableHeader">
            <tr className="requestTableHeaderRow">
              {Object.keys(MasterCxt.selectedrequestobject).length === 0 && (
                <td className="requestTableHeaderSelect">Select</td>
              )}
              <td className="requestTableHeaderCourse">PG MD/MS Course</td>
              <td className="requestTableHeaderSeat">Existing Seats</td>
              <td className="requestTableHeaderSeatDisplay">Add/Remove Seats</td>
              <td className="requestTableHeaderSeatTotal">Total</td>
              <td className="requestTableHeaderFileUpload">LOP</td>
              <td className="requestTableHeaderFileUpload">LOP</td>
              <td className="requestTableHeaderSave">Save</td>
            </tr>
          </th>
          <tbody className="requestTableBody">
            {MasterCxt.pgmdmstabledata.map((row, index) => (
              <tr
                key={row.id}
                className={`requestTableBodyRow ${isDisabled ? "disable" : ""}`}
              >
                {Object.keys(MasterCxt.selectedrequestobject).length === 0 && (
                  <td className="requestTableBodySelect">
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      checked={row.editable}
                      onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                      }
                    />
                  </td>
                )}
                <td
                  className={`requestTableBodyCourse ${
                    row.editable ? "active" : ""
                  }`}
                >
                  {row.course}
                </td>
                <div className="requesttablebody">
                <td className="requestTableBodySeat">
                  <span className="requestTableBodySeatTextarea">
                  <TextField
                    type="number"
                    value={row.seats}
                    disabled={true}
                    placeholder={`Max ${row.intake}`}
                    // onChange={(e) => handleInputChange(index, e.target.value)}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Removes the default border
                        },
                      },
                    }}
                  />
                  </span>
                </td>
                </div>
              
                                <td className="requestTableBodySeatDisplay"> 
                                  {/* <div> */}
                                     <Button className="requestTableBodySeatIcon" 
                                     disabled = {!row.editable}
                                     onClick={()=>handleChangeSeats(index, row?.change, "decrease")}
                                     >-</Button>
                                     
                                     <span>
                                      <TextField
                                      className="requestTableBodySeatDisplayTextArea"
                                      fullWidth
                                      type="number"
                                      name={"change"}
                                      // onChange={(e) => handleInputChange(index, e.target.value)}
                                      value={row?.change}
                                      disabled={!row.editable}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            border: "none",
                                          },
                                        },
                                      }}
                                      />
                                     </span>
                                     <Button className="requestTableBodySeatIcon"
                                     disabled = {!row.editable}
                                     onClick={()=>handleChangeSeats(index, row?.change, "increase")}
                                     >+</Button>
                                  {/* </div> */}
                                </td>
                                {/* <div className="requesttablebody"> */}
                                <td className="requestTableBodySaveTextarea">
                                  {/* <span  className="requestTableBodySaveTextarea"> */}
                                  {/* <TextField
                                    fullWidth
                                    type="number"
                                    value={row.totalseats}
                                    value={row.totalseats}
                                    disabled={!row.editable}
                                    // placeholder={`Max ${row.intake}`} 
                                    //  onChange={(e) => handleInputChange(index, e.target.value)}
                                    //  onChange={(e) => handleInputChange(index, e.target.value)}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          border: "none",
                                        },
                                      },
                                    }}
                                  /> */}
                                  <label className="requestTableBodySaveTextarea">{row.totalseats}</label>
                                  {/* </span> */}
                                </td>
                                {/* </div> */}
                                <td className="requestTableBodyFileUpload">
                                  <div >
                                    <input 
                                  type="file"
                                 hidden
                                 id="upload-lor"
                                 onChange={(e) => handlefilechange(e, row.id, index)}
                                 disabled = {!row.editable}
                                  />
                                  <div className="requestTableBodyFileUploadSeat">
                                  <label for = "upload-lor" className="requestTableBodyFileUploadLabel">Upload LOP </label> 
                                  <span><UploadSvgIcon /></span>
                                  </div>
                                  </div>
                                </td>
                                <td>
                                 <Button
                                  // className="success"
                                  //   buttonText="Save"
                                  color="success"
                                  variant="contained"
                                      onClick={MasterCxt.handlerequestcourses}
                                      disabled={!(row.filename?.length>0)}            
                                >Save
                                  </Button>
                                    
                                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Alert to show success or error message */}

      <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          severity={messageType}
          sx={{ width: "100%" }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loading Spinner */}

      {MasterCxt.isloading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1300,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
