import axios from "axios";
import Master from "../Master";
import "../Css/RegistrationPage.css";
import { Link } from "react-router-dom";
import Text from "../elements/text/Text";
import Button from "../elements/button/Button";
import { Snackbar, Alert } from "@mui/material";
import Dropdown from "../elements/dropdown/Dropdown";
import React, { useContext, useEffect, useState } from "react";

export default function RegistrationPage() {
  const MasterCxt = useContext(Master);

  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [formhaserror, setformhaserror] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [disableregisterbutton, setdisableregisterbutton] = useState(false);

  const handlechange = (name, value) => {
    MasterCxt.updateregistrationformdata(name, value);
  };

  const updateformvalidationdata = (name, value) => {
    MasterCxt.updateregistrationformvalidationdata(name, value);
  };

  const handlelogin = () => {
    MasterCxt.navigate("/login");
  };

  useEffect(() => {
    let haserrors = MasterCxt.doesformhaserror(
      MasterCxt.registrationformvalidationdata
    );

    setformhaserror(haserrors);
  }, [MasterCxt.registrationformvalidationdata]);

  const handlesubmit = (e) => {
    e.preventDefault();
    seterrorMessage("");
    MasterCxt.setisloading(true);
    setdisableregisterbutton(true);
    
    axios({
      method: "POST",
      url: MasterCxt.baseurl + "/register",
      data: MasterCxt.registrationformdata,
      headers: {
        authorization: "your token comes here",
      },
    })
      .then((response) => {
        if (response.data.success) {
          setsuccessMessage(true);
          setMessageType("success");
          setData(response.data.data);
          setMessage(response.data.message);
        } else {
          setMessageType("error");
          setMessage(response.data.message);
          seterrorMessage(
            response.data.message || "Registration failed. Please try again."
          );
        }
      })
      .catch((error) => {
        setMessageType("error");
        setMessage(error.response.data.message);
        seterrorMessage(
          error.response?.data?.message ||
            "An error occurred during registration."
        );
      })
      .finally(() => {
        MasterCxt.setisloading(false); // Stop loading
        setOpenSnackbar(true);
      });
  };

  useEffect(() => {
    axios
      .get(MasterCxt.baseurl + "/getmanagementtypes")
      .then((response) => {
        if (response.data.success) {
          MasterCxt.setmanagementtypes(response.data.data);
          setMessage("Management Types fetched successfully");
          setMessageType("success");
        }

        if (!response.data.success) {
          setMessage(response.data.message);
          setMessageType("error");
        }
      })
      .catch((err) => {
        setMessageType("error");
        setMessage(err.data.message);
      })
      .finally(() => {
        MasterCxt.setisloading(false); // Stop loading
        setOpenSnackbar(true);
      });
  }, []);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="registrationPageMainContainer">
      <div className="registrationPageHeader">
        College Registration
        <div className="registrationPageSubHeader">
          Please enter your college details here.
        </div>
      </div>
      <form onsubmit={handlesubmit} className="registrationPageForm">
        <label className="registrationPageFormLabel">
          Institution / College Name
        </label>
        <Text
          required
          name="collegename"
          errortext="College name is required"
          sethaserror={updateformvalidationdata}
          value={MasterCxt.registrationformdata.college}
          update={(value) => handlechange("collegename", value)}
        />
        <label className="registrationPageFormLabel">College Address</label>
        <Text
          required
          name="address"
          className="registerinputfields"
          sethaserror={updateformvalidationdata}
          errortext="Please provide the college address"
          update={(value) => handlechange("address", value)}
          value={MasterCxt.registrationformdata.collegeaddress}
        />
        <div className="registrationPageFormInnerDiv">
          <div className="registrationPageFormInnerDivNo">
            <label className="registrationPageFormLabel">
              Telephone Number
            </label>
            <Text
              required
              name="telephone"
              regx={/^\d{10}$/} // Regex for 10-digit telephone number
              className="registerinputfields"
              sethaserror={updateformvalidationdata}
              errortext="Please enter a valid telephone number"
              update={(value) => handlechange("telephone", value)}
              value={MasterCxt.registrationformdata.telephonenumber}
            />
          </div>
          <div className="registrationPageFormInnerDivNo">
            <label className="registrationPageFormLabel">Email Address</label>
            <Text
              name="email"
              required={true}
              regx={/^\S+@\S+\.\S+$/} // Email regex for validation
              className="registerinputfields"
              sethaserror={updateformvalidationdata}
              errortext="Please enter a valid email address"
              update={(value) => handlechange("email", value)}
              value={MasterCxt.registrationformdata.emailaddress}
            />
          </div>
        </div>
        <label className="registrationPageFormLabel">College Website URL</label>
        <Text
          required
          name="websiteurl"
          className="registerinputfields"
          sethaserror={updateformvalidationdata}
          errortext="Please enter a valid website URL"
          update={(value) => handlechange("websiteurl", value)}
          value={MasterCxt.registrationformdata.collegewebsite}
          // regx={/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/} // Regex for URL
        />
        <label className="registrationPageFormLabel">Registration Number</label>
        <Text
          required
          regx={/^[a-zA-Z0-9]+$/} // Alphanumeric regex for registration number
          name="registrationnumber"
          className="registerinputfields"
          sethaserror={updateformvalidationdata}
          label="Registration Number of the Managing Body"
          value={MasterCxt.registrationformdata.registrationnumber}
          update={(value) => handlechange("registrationnumber", value)}
        />
        <label className="registrationPageFormLabel">Principal Number</label>
        <Text
          required
          regx={/^\d{10}$/} // Regex for 10-digit mobile number
          name="principalmobile"
          className="registerinputfields"
          sethaserror={updateformvalidationdata}
          errortext="Please enter a valid mobile number"
          update={(value) => handlechange("principalmobile", value)}
          value={MasterCxt.registrationformdata.principalmobilenumber}
        />
        <label className="registrationPageFormLabel">Management Type</label>
        <Dropdown
          labelField="label"
          valueField="value"
          name={"managementtypeid"}
          className="custom-select"
          options={MasterCxt.managementtypes}
          placeholder="Select Management Type"
          onChange={(name, value) => {
            handlechange(name, value);
            updateformvalidationdata(name, value);
          }}
          values={MasterCxt.managementtypes.filter(
            (opt) => opt.value === MasterCxt.selectedoption?.value
          )}
        />
        <div className="registerPageBtnDiv">
          <Button
            label="Register"
            onclick={handlesubmit}
            className="registerPageBtn"
            disabled={formhaserror || disableregisterbutton}
          />
        </div>
        <div className="registerPageFooter">
          <span className="registerPageFooterLabel">
            Already have an account?
          </span>

          <span
            className="registerPageFooterLogin"
            onClick={() => {
              handlelogin();
              seterrorMessage("");
            }}
          >
            Login
          </span>
        </div>
      </form>
      <div className="displaymsg">
        {successMessage && (
          <div className="success-message">
            <p className="registermessage">
              Registration successful! Thank you for signing up.
            </p>
            <p className="registermessage">
              Your login details have been sent to your email. Please check your
              inbox and log in.
            </p>
            <p className="registermessage">
              <Link
                className="loginmsg"
                to="/login"
                onClick={() => {
                  seterrorMessage("");
                  setsuccessMessage("");
                }}
              >
                Click here to login
              </Link>
            </p>
          </div>
        )}

        {errorMessage && (
          <div className="error-message">
            <p className="registermessage">{errorMessage}</p>
          </div>
        )}
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={messageType}
          sx={{ width: "100%" }}
          onClose={handleSnackbarClose}
        >
          {message}
        </Alert>
      </Snackbar>

      {MasterCxt.isloading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}
