// import React, { useState, useEffect,useContext } from 'react';  
// import './Paymentprovisional.css';
// import axios from 'axios';
// import Master from "./Master";

// const Paymentprovisional = () => {

//   const {
//     baseurl,userid, academicyearid,selectedrequestobject, token,roleid,
//      setselectedrequestobject, 
//      setassessorpaymentcomplete,assessorpaymentcomplete,
//     setaffiliationpaymentcomplete, affiliationpaymentcomplete
//  } = useContext(Master);




// const [isconfirmed, setisconfirmed] = useState(false);
// const [paymentdata, setpaymentdata] = useState([]);
// const [reload, setreload] = useState(false);

// const handlecheckboxchange = (event) => {
//   setisconfirmed(event.target.checked);
// };


// function handlerequestpayment(){


// axios({
//   url: baseurl+"/makepaymentrequest",
//   method: "POST",
//   headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`  ,
//   },

//     data: {
//       affiliationrequestid:selectedrequestobject.requestid,
//       paymenttypeid:3,
//       status:0,
//       amount:15000,
//       bankrequestid:111111111
// }


  
// }
// )
//   .then((response) => {         
//       if(response.data.success){

//         setreload(!reload);

//       }

//   })
//   .catch((error) => {

//   });

// }



// function handlermakepayment(){


// axios({
//   url: baseurl+"/makepayment",
//   method: "POST",
//   headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`  ,
//   },

//     data: {
//       affiliationrequestid:selectedrequestobject.requestid,
//       paymenttypeid:3,
//       status:1,
//       utr:"9876789065",
//       amount:15000,
//       // bankrequestid:
// }


  
// }
// )
//   .then((response) => {         
//       if(response.data.success){
//         setreload(!reload);
//       }

//   })
//   .catch((error) => {

//   });

// }







// useEffect(() => {


//   axios({
//     url: baseurl+"/getpaymentdetails",
//     method: "POST",
//     headers: {
//          authorization: `Bearer ${localStorage.getItem("token")}`  ,
//     },
//     data: {affiliationrequestid:selectedrequestobject.requestid,
//             userid:selectedrequestobject.userid,
//             paymenttypeid:3
//     },
    
// }).then((response) => {

// if(response.data.success){
//   if(response.data.data.length>0){
//     setpaymentdata(response.data.data[0])

//     if(response.data.data[0].status==1){
      
//     setselectedrequestobject((prevdata)=>({...prevdata,showassessorselection:true}))
    
//     }else{
//       setselectedrequestobject((prevdata)=>({...prevdata,showassessorselection:false}))
//     }



 
// }else{
//   selectedrequestobject((prevdata)=>({...prevdata,showassessorselection:false}))
//   setpaymentdata([])
// }



// }




// })
// .catch((error) => {
  

// });






// }, [,reload]);


//   return (
//     <div className='paymentwrapper'>
//     <>
//    {(roleid == process.env.REACT_APP_ADMIN || roleid == process.env.REACT_APP_COLLEGE) &&
//     <div className="payment-container">
//       <h2>Provisional Payment</h2>
//       { roleid == process.env.REACT_APP_ADMIN && (paymentdata.status==null ) && <>
//       <div className="checkbox-container">
//         <input
//           type="checkbox"
//           id="accessor-fee"
//           checked={isconfirmed}
//           onChange={handlecheckboxchange}
//         />
//         <label htmlFor="accessor-fee">Confirm Provisional Fee</label>
//       </div>
//       <button
//         className="request-button"
//         disabled={!isconfirmed}
//         onClick={() => handlerequestpayment()}
//       >
//         Request Provisional Payment
//       </button>


//       {/* <button
//         className="request-button"
//         disabled={!isconfirmed}
//         onClick={() => 
//       >
//         Request Payment
//       </button> */}
// </>
//     }


// { paymentdata.status!=null && <>
// <>

// <h3>Status: {paymentdata.status==1?"Payment Successfull UTR:+ "+paymentdata.utr:"Payment Pending"}</h3>

// </>


// </>}

// { (roleid == process.env.REACT_APP_COLLEGE && paymentdata.status==null) && <>

// Payment notification will be sent to you shortly.


// </>
// }


//     {roleid == process.env.REACT_APP_COLLEGE && (paymentdata.status==0 ) && <>
    
//       <button
//         className="request-button"
//         // disabled={!isconfirmed}
//         onClick={() => handlermakepayment()}
//       >
//         Make Payment
//       </button>
    
//     </>}
//     </div>
// }
//     </>
//     </div>
//   );
// };

// export default Paymentprovisional;








import React, { useState, useEffect,useContext, useRef } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography } from '@mui/material';
import Master from './Master';
import { set } from 'lodash';
import Paymentassessor from './Paymentassessor';
import Paymentaffiliation from './Paymentaffiliation';
import generatePDF from 'react-to-pdf';
const Paymentprovisional = ( ) => {
  const {
      assessorpaymentcomplete,affiliationpaymentcomplete, setselectedrequestobject
 } = useContext(Master);

  // Fetch course details on component mount or when requestid or token changes
  // useEffect(() => {

  //   if(assessorpaymentcomplete&& affiliationpaymentcomplete){
  //     setselectedrequestobject((prevdata)=>({...prevdata,showassessorselection:true}))
  //   }else{
  //     setselectedrequestobject((prevdata)=>({...prevdata,showassessorselection:false}))
  //   }
    

  // }, [assessorpaymentcomplete,affiliationpaymentcomplete]); // Trigger re-fetch if requestid or token changes

  // const targetRef = useRef();
  const targetRef = useRef();

  return (
    <>
    <div ref={targetRef} >
    {/* <div className="component-header"> Provisional fee </div> */}
    <Paymentassessor />
    <Paymentaffiliation />
  
  </div>
  <button className="download-payment" onClick={() => generatePDF(targetRef, {filename: 'Payment-Details.pdf'})}>Download PDF</button>
  </>
  );
};

export default Paymentprovisional;
