import _ from "lodash";
import axios from "axios";
import Master from "../../Master";
import "../../Css/RequestDetailPage/CourseSelector.css";
import React, { useContext, useEffect, useState } from "react";

import {
  Alert,
  Checkbox,
  Radio,
  Snackbar,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

export default function CourseSelector() {
  const MasterCxt = useContext(Master);

  // const [localselectedprogram, setlocalselectedprogram] = useState({
  //   UG: false,
  //   PG: false,
  // });

  const [localselectedprogram, setlocalselectedprogram] = useState("");

  const [localpgsuboptions, setlocalpgsuboptions] = useState("");
  const [localugsuboptions, setlocalugsuboptions] = useState("");
  const [localprovisionalsuboption,setlocalprovisionalsuboption]=useState("");

  const handleProgramChange = (event) => {
    const { value } = event.target;
    MasterCxt.updateselectedprogram(value);

    setlocalselectedprogram(value);
    // Reset PG suboptions if switching to UG


    // MasterCxt.updatepgsuboptions(value);
    // setlocalpgsuboptions(value);

  };

  const handlepgsuboptionschange = (event) => {
    const { value } = event.target;
    MasterCxt.updatepgsuboptions(value);
    setlocalpgsuboptions(value);
  };


  const handleugsuboptionschange = (event) => {
    const { value } = event.target;
    MasterCxt.updateugsuboptions(value);
    setlocalugsuboptions(value);
  };

  const handleprovisionalsuboptionchange = (event) => {
    const { value } = event.target;
    MasterCxt.setprovisionalsuboption(value);
    setlocalprovisionalsuboption(value);
    MasterCxt.setprovisionalsuboption(value);
  };


  useEffect(() => {
    if (
      MasterCxt.selectedrequestobject.requestid !== "undefined" &&
      typeof MasterCxt.selectedrequestobject.program === "undefined"
    ) {
      axios({
        method: "GET",
        url: MasterCxt.baseurl + "/courses/requests/" + MasterCxt.selectedrequestobject.requestid,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          if (response.data.success) {
            const allrequestcourses = response.data.data;
            const uniquecourses = _.uniq(_.map(allrequestcourses, "program"));
            setlocalselectedprogram(uniquecourses[0]);
            MasterCxt.setselectedprogram(uniquecourses[0]); 

            if(uniquecourses[0]=="PG"){
            const pgcategories = _.uniq(
              _.map(
                _.filter(allrequestcourses, (n) => n.program === "PG"),
                "category"
              )
            );
            setlocalpgsuboptions(pgcategories[0].replace("/", "_"));
            MasterCxt.setpgsuboptions(pgcategories[0].replace("/", "_"));
            if(allrequestcourses[0].seats==0){
              
              setlocalprovisionalsuboption("new");
              MasterCxt.setprovisionalsuboption("new");
            }
            if(allrequestcourses[0].seats>0){
              
              setlocalprovisionalsuboption("change");
              MasterCxt.setprovisionalsuboption("change");
            }
          }


          if(uniquecourses[0]=="UG"){
            
            const ugcategories = _.uniq(
              _.map(
                _.filter(allrequestcourses, (n) => n.program === "UG"),
                "course"
              )
            );

            setlocalugsuboptions(ugcategories[0]);
            MasterCxt.setugsuboptions(ugcategories[0]);
          }

            // let updateuniquecourses = {};

            // uniquecourses.forEach((course) => {
            //   updateuniquecourses = { ...updateuniquecourses, [course]: true };
            // });

            // // setlocalselectedprogram(updateuniquecourses);
            // // MasterCxt.setselectedprogram(updateuniquecourses);

            // updateuniquecourses = {};

            // pgcategories.forEach((category) => {
            //   updateuniquecourses = {
            //     ...updateuniquecourses,
            //     [category.replace("/", "_")]: true,
            //   };
            // });


            // ugcategories.forEach((category) => {
            //   updateuniquecourses = {
            //     ...updateuniquecourses,
            //     [category.replace("/", "_")]: true,
            //   };
            // });
            // setlocalugsuboptions(updateuniquecourses);
            // MasterCxt.setugsuboptions(updateuniquecourses);

            // setlocalpgsuboptions(updateuniquecourses);
            // MasterCxt.setpgsuboptions(updateuniquecourses);
          }
        })
        .catch((error) => {
          // Handle error if necessary
        })
        .finally(() => {
          MasterCxt.setisloading(false);
        });
    }

    return () => {
      // Clean up on unmount if necessary
    };
  }, []);


  useEffect(()=>{
    
    if(MasterCxt.newrequest){
      console.log("KAKAKAKA");
      
   setlocalselectedprogram(""); 

   setlocalpgsuboptions("");
   setlocalugsuboptions("");
   setlocalprovisionalsuboption("");
   MasterCxt.setprovisionalsuboption("");
    }

  },[MasterCxt.selectedparent])
  

  const isDisabled =
    MasterCxt.affiliationrequestid != null ||
    Object.keys(MasterCxt.selectedrequestobject).length > 0;

  return (
    <div className="courseSelectorMainContainer">
      <div className="courseSelectorGroup" disabled={isDisabled}>
        <span className="courseSelectorLabel">Select course type :</span>
        <FormControlLabel
          control={
            <Radio
              name="program"
              disabled={isDisabled}
              value="UG"
              onChange={handleProgramChange}
              checked={localselectedprogram == "UG"}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />
          }
          label={<span className="courseSelectorGroupLabel">UG</span>}
        />
        <FormControlLabel
          control={
            <Radio
              name="program"
              value= "PG"
              disabled={isDisabled}
              onChange={handleProgramChange}
              checked={localselectedprogram=="PG"}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />
          }
          label={<span className="courseSelectorGroupLabel">PG</span>}
        />
      </div>
      {localselectedprogram == "PG" && (
        <div className="courseSelectorGroup" disabled={isDisabled}>
          <span className="courseSelectorLabel">Select PG type :</span>
          <FormControlLabel
            control={
              <Radio
                name = "pgsubprogarm"
                value="MD_MS"
                disabled={isDisabled}
                onChange={handlepgsuboptionschange}
                checked={localpgsuboptions == "MD_MS"}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">MD/MS</span>}
          />
          <FormControlLabel
            control={
              <Radio
               name = "pgsubprogarm"
                value="MDS"
                disabled={isDisabled}
                checked={localpgsuboptions == "MDS"}
                onChange={handlepgsuboptionschange}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">MDS</span>}
          />
        </div>
      )}
    
      {localselectedprogram == "UG" && (
        <div className="courseSelectorGroup" disabled={isDisabled}>
          <span className="courseSelectorLabel">Select UG type :</span>
          <FormControlLabel
            control={
              <Radio
                name = "ugsubprogarm"
                value="MBBS"
                disabled={isDisabled}
                onChange={handleugsuboptionschange}
                checked={localugsuboptions == "MBBS"}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">MBBS</span>}
          />
          <FormControlLabel
            control={
              <Radio
               name = "ugsubprogarm"
                value="BDS"
                disabled={isDisabled}
                checked={localugsuboptions == "BDS"}
                onChange={handleugsuboptionschange}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">BDS</span>}
          />
        </div>
)}


{(    (MasterCxt.affiliationtypeid == 4 && localselectedprogram!="" ) &&
      (localugsuboptions!="" || localpgsuboptions!="")
) && (
        <div className="courseSelectorGroup" disabled={isDisabled}>
          <span className="courseSelectorLabel">Select Type :</span>
          <FormControlLabel
            control={
              <Radio
                name = "provisionalsuboption"
                value="new"
                disabled={isDisabled}
                onChange={handleprovisionalsuboptionchange}
                checked={localprovisionalsuboption == "new"}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">Add New courses</span>}
          />
          <FormControlLabel
            control={
              <Radio
               name = "provisionalsuboption"
                value="change"
                disabled={isDisabled}
                checked={localprovisionalsuboption == "change"}
                onChange={handleprovisionalsuboptionchange}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
              />
            }
            label={<span className="courseSelectorGroupLabel">Increase/Decrease seats</span>}
          />
        </div>
)}

      {MasterCxt.isloading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}

      {/* Optional Snackbar for Notifications */}
      {/* <Snackbar
        open={false} // Set this to true if you want the snackbar to appear based on an event
        onClose={() => {}}
        autoHideDuration={6000}
      >
        <Alert onClose={() => {}} severity="success" sx={{ width: "100%" }}>
          Data loaded successfully!
        </Alert>
      </Snackbar> */}
    </div>
  );
}
